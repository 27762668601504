import React from "react";
import LessonNavigator from "./LessonNavigator";
import {connect} from "react-redux";
import {Card, Intent} from "@blueprintjs/core";
import {AbstractCourseViewer, mapStateToCourseViewerProps} from "./AbstractCourseViewer";
import changeCoursePosition from "../../thunks/changeCoursePosition";
import {Elevation} from "@blueprintjs/core/lib/esm/common/elevation";
import HomeworkReviewer from "./HomeworkReviewer";

class CourseMessageViewer extends AbstractCourseViewer{

    render() {
        return this.props.course.contentData.modules[this.props.position.selectedModule] ?
            <Card className='sidebar-layout' interactive={false} elevation={Elevation.THREE}>
            <LessonNavigator
                modules={this.props.course.contentData.modules}
                onSelect={this.handleSelect}
                intent={Intent.PRIMARY}
                selectedModule={this.props.position.selectedModule}
                selectedLesson={this.props.position.selectedLesson}
                />
            <HomeworkReviewer course={this.props.course}/>
        </Card> : null;
    }

}

export default connect(
    mapStateToCourseViewerProps,{changeCoursePosition})(CourseMessageViewer);