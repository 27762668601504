import {ThunkAction} from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Action} from "redux";
import {ICourseStatus} from "../model/courses/CourseReviewStatus";
import {getCourseStatus, getCourseStatusSubscription} from "../service/Firebase";
import {InstrumentType} from "../actions/instruments";
import {useTool} from "../actions/tools";
import {receiveCourseStatus} from "../actions/data";
import {AppToaster} from "../service/toaster";
import {
    review_status_accepted, review_status_process,
    review_status_rejected
} from "../text/Literals";
import {Intent} from "@blueprintjs/core";
import {ACCEPTED, PROCESS, REJECTED, REQUEST} from "../service/Literals";

export default(): ThunkAction<void, AppState, null, Action> =>  async (dispatch, getState)  => {
    const topic = getState().instruments.entity.idAsString();
    const status : ICourseStatus = await  getCourseStatus(topic);
    dispatch(receiveCourseStatus(topic,status));
    dispatch(useTool(InstrumentType.TeachersCourses,InstrumentType.ReviewWindow))
}

export const subscribeForStatus=(): ThunkAction<void, AppState, null, Action> => async (dispatch, getState)  => {
    const topic = getState().instruments.entity.idAsString();
    const docRef = await  getCourseStatusSubscription(topic);
    const unsubscribable =docRef.onSnapshot(snapshot => {
        console.log("got status changed");
        const status : ICourseStatus = snapshot.data() as ICourseStatus;
        console.log(status);
        dispatch(receiveCourseStatus(topic, status));
        let intent : Intent;
        let msg : string;
        switch (status.status) {
            case ACCEPTED:
                intent ="success";
                msg = review_status_accepted;
                unsubscribable();
                break;
            case REJECTED:
                intent = "danger";
                msg = review_status_rejected;
                unsubscribable();
                break;
            case PROCESS:
                msg = review_status_process;
                intent = "warning";
                break;
            default:
            case REQUEST:
                return;
        }
        AppToaster.show({
            intent,
            icon: "learning",
            message: getState().data.vocabulary[msg],
        });
    });
};
