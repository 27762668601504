import React, {PureComponent} from "react";
import InstrumentContainer from "../InstrumentContainer";
import {InstrumentType} from "../../actions/instruments";
import {Button, Drawer, Intent, Position, Tooltip} from "@blueprintjs/core";
import {
    add_course_modules, delete_draft_course,
    homework_viewer,
    preview_label,
    review_description,
    send_to_review
} from "../../text/Literals";
import CourseEditor from "../editors/CourseEditor";
import {Vocabulary} from "../../model/AppData";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import CourseMessageViewer from "../courses/CourseMessageViewer";
import {useTool} from "../../actions/tools";
import {Txt} from "../tools/Text";
import {sendInstrumentRequest} from "../../thunks/useInstrument";
import showReviewTool from "../../thunks/showReviewTool";

interface CourseEditorInstrumentProps {
    vocabulary : Vocabulary;
    useTool : any;
    sendInstrumentRequest : any;
    showReviewTool : any;
    isDraft ?: boolean;
}

interface CourseEditorInstrumentState {
    showPreview : boolean;
    showMessages : boolean;
    showReview : boolean;
}

class CourseEditorInstrument extends PureComponent<CourseEditorInstrumentProps,CourseEditorInstrumentState>{

    constructor(props: CourseEditorInstrumentProps, context: any) {
        super(props, context);
        this.state = {
            showPreview : false,
            showMessages : false,
            showReview : false
        }
    }

    handlePreviewShow=()=>{
        this.props.sendInstrumentRequest(InstrumentType.TeachersCourses);
        this.setState({...this.state,showPreview : true});
    };

    handlePreviewClose=()=>{
        this.setState({...this.state,showPreview : false});
    };

    handleMessagesToggle=()=>{
      this.setState({...this.state,showMessages : !this.state.showMessages});
    };

    render() {
        console.log(this.props.isDraft);
        return (
            <InstrumentContainer
                fullscreen={true}
                onTop={true}
                instrument={InstrumentType.TeachersCourses}
                title={new Txt().dict("courses_label").txt(': ')}
                titleFactory={()=>(<div className='tiles-container-adjustable'>
                        <Tooltip content={this.props.vocabulary[preview_label]}
                                 className='instruments-tool-button--5' wrapperTagName='div' targetTagName='div'  targetClassName='fill-width'>
                            <Button
                                className='fill-width'
                                title={this.props.vocabulary[preview_label]}
                                icon={'eye-open'}
                                intent={Intent.SUCCESS}
                                onClick={this.handlePreviewShow}>
                                {this.props.vocabulary[preview_label]}
                            </Button>
                        </Tooltip>
                        <Tooltip content={this.props.vocabulary[review_description]}
                                 className='instruments-tool-button--5' wrapperTagName='div' targetTagName='div'  targetClassName='fill-width'>
                            <Button
                                className='fill-width'
                                title={this.props.vocabulary[send_to_review]}
                                icon='globe'
                                intent={Intent.WARNING}
                                onClick={()=>{this.props.showReviewTool()}}>
                                {this.props.vocabulary[send_to_review]}
                            </Button>
                        </Tooltip>
                        <Tooltip content={this.props.vocabulary[homework_viewer]}
                                 className='instruments-tool-button--5' wrapperTagName='div' targetTagName='div'  targetClassName='fill-width'>
                            <Button
                                className='fill-width'
                                title={this.props.vocabulary[homework_viewer]}
                                icon={'learning'}
                                intent={Intent.PRIMARY}
                                onClick={this.handleMessagesToggle}>
                                {this.props.vocabulary[homework_viewer]}
                            </Button>
                        </Tooltip>
                        <Tooltip content={this.props.vocabulary[add_course_modules]}
                                 className='instruments-tool-button--5' wrapperTagName='div' targetTagName='div' targetClassName='fill-width'>
                            <Button
                                className='fill-width'
                                title={this.props.vocabulary[add_course_modules]}
                                icon={'diagram-tree'}
                                intent={Intent.NONE}
                                onClick={()=>{this.props.useTool(InstrumentType.TeachersCourses,InstrumentType.TemplateEditor)}}>
                                {this.props.vocabulary[add_course_modules]}
                            </Button>
                        </Tooltip>
                        { this.props.isDraft ?
                            <Tooltip content={this.props.vocabulary[delete_draft_course]}
                                     className='instruments-tool-button--5' wrapperTagName='div' targetTagName='div'
                                     targetClassName='fill-width'>
                                <Button
                                    className='fill-width'
                                    title={this.props.vocabulary[delete_draft_course]}
                                    icon='trash'
                                    intent={Intent.DANGER}
                                    onClick={() => {
                                        this.props.useTool(InstrumentType.TeachersCourses, InstrumentType.DeleteCourseTool)
                                    }}>
                                    {this.props.vocabulary[delete_draft_course]}
                                </Button>
                            </Tooltip> : null}
                    </div>
                )}
            >
                <CourseEditor showPreview={this.state.showPreview} onClosePreview={this.handlePreviewClose}/>
                 <Drawer
                     onClose={this.handleMessagesToggle}
                     className='instruments-window'
                     isOpen={this.state.showMessages }
                     title={this.props.vocabulary[homework_viewer]}
                     size={'100%'}
                     isCloseButtonShown={true}
                     canOutsideClickClose={true}
                     position={Position.TOP}>
                    <CourseMessageViewer/>
                 </Drawer>
            </InstrumentContainer>
        );
    }
}

const mapStateToProps = (state :AppState) =>{
    return {
        vocabulary : state.data.vocabulary,
        isDraft : state.instruments.entity && state.user.getTeachersCourseById(state.instruments.entity.idAsString()) && state.user.getTeachersCourseById(state.instruments.entity.idAsString())!.isDraft
    }
};


export default connect(mapStateToProps,{useTool,sendInstrumentRequest,showReviewTool})(CourseEditorInstrument);