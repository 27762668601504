import {becomeIdle, Process,ProcessType} from "../actions/process";

export default function (process : Process = becomeIdle(), action : Process) :Process {
    switch (action.type) {
        case ProcessType.WaitingForServerResponse:
        case ProcessType.Idle:
            return action;
        default:
            return process;
    }
}