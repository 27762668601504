import {SimpleRequestData} from "../adaptors/RequestData";
import {FormAdaptor, IFormAdaptor} from "../adaptors/FormAdaptor";
import {
    FieldAdaptor,
    FormFieldAdaptor,
    NotEmptyStringFiled,
    RemoveTagAdaptor,
    TagsAdaptor,
    TextFieldAdaptor
} from "../adaptors/FieldAdaptor";
import {email_body, email_recipients, email_subject, not_empty_field} from "../text/Literals";
import {Method} from "../model/Request";
import {DataType} from "../actions/data";

export interface IMailRequest{
    recipients : string[];
    subject : string;
    body : string;
}

export interface MailResponse{
    sent : number;
    invalid : number;
    noMX : number;
    notFound : number;
    bounced : number;
}

export class MailRequest extends SimpleRequestData implements IMailRequest{
    recipients: string[];
    allRecipients : string [];
    subject: string;
    body : string;
    readonly fields : string[] = ['recipients','subject','body'];
    recipientsAdaptor : TagsAdaptor;
    subjectAdaptor : FormFieldAdaptor<string>;
    bodyAdaptor : FormFieldAdaptor<string>;

    constructor(recipients: string[]) {
        super();
        this.recipients = recipients;
        this.allRecipients = [...recipients];
        this.subject = "";
        this.body = "";

        this.recipientsAdaptor=this.initRecipientsAdaptor();
        this.subjectAdaptor = this.initSubjectAdaptor();
        this.bodyAdaptor = this.initBodyAdaptor();

        this.adaptor = this.createFormAdaptor();
    }

    setSubject=(value :string)=> {
        this.subject = value;
        this.initSubjectAdaptor();
        this.onDataChanged!(this.createFormAdaptor());
    };

    setBody=(value :string)=> {
        this.body = value;
        this.initBodyAdaptor();
        this.onDataChanged!(this.createFormAdaptor());
    };

    initSubjectAdaptor = ()=>{
        this.subjectAdaptor = new NotEmptyStringFiled(email_subject,this.setSubject,not_empty_field,this.subject);
        return this.subjectAdaptor;
    }

    initBodyAdaptor = ()=>{
        this.bodyAdaptor = new TextFieldAdaptor(email_body,this.setBody,this.body,20);
        return this.bodyAdaptor;
    }

    initRecipientsAdaptor = ()=>{
        this.recipientsAdaptor = new RemoveTagAdaptor(email_recipients,this.recipients, this.allRecipients,
            this.addRecipient,this.removeRecipient,appData => ([]), false);
        return this.recipientsAdaptor;
    };

    addRecipient=(val : string)=>{
        this.recipients.push(val);
        this.initRecipientsAdaptor();
        this.onDataChanged!(this.createFormAdaptor());
    };

    removeRecipient=(val : string)=>{
        if (this.recipients.length < 2)
            return;
        const index = this.recipients.indexOf(val);
        if (index < 0) return;
        this.recipients.splice(index,1);
        this.initRecipientsAdaptor();
        this.onDataChanged!(this.createFormAdaptor());
    };

    getMethod(): Method {
        return Method.POST;
    }

    getType(): DataType {
        return DataType.SchoolMail;
    }

    getFieldAdaptors():FieldAdaptor[]{
        return [
            this.recipientsAdaptor,
            this.subjectAdaptor,
            this.bodyAdaptor
        ];
    }

    createFormAdaptor=(): IFormAdaptor=> {
        return new FormAdaptor( this.getFieldAdaptors(),this);
    }

}