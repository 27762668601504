import React, {FC} from 'react';
import {Tag, Tooltip} from "@blueprintjs/core";
import {getRGBStyle} from "../viewers/KeyValueViewer";
import {sendRequest} from "../../thunks/sendRequest";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {User} from "../../model/User";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {KV, RequestKV, SaveKV} from "../../model/KV";
import {add_label, common_caption_26, license_expiration} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";
import {IExpire} from "../../api/School";


interface TagRequestViewProps {
    adaptors ?:KV[];
    user : User;
    sendRequest : any;
    label ?: string;
    intent ?: Intent;
    labelForEmpty ?:string;
    deleteTooltip ?:string;
    addTooltip ?:string;
    vocabulary :Vocabulary;
    useVocabulary ?: boolean;
    expire ?: IExpire;
}


const TagRequestView : FC<TagRequestViewProps> = props => {
    if (props.adaptors === undefined) return null;

    function onRemove(adaptor: RequestKV|SaveKV) {
        if(adaptor instanceof  RequestKV) {
            props.sendRequest(adaptor.generateRequest(props.user.token), adaptor.request, props.user);
            return;
        }
        adaptor.save(adaptor.value);
    }

    function getTagString(key : string) {
        return props.useVocabulary ? props.vocabulary[key] : key;
    }

    function expire(kv : KV) : Date|undefined{
        return props.expire ? props.expire[kv.key] :undefined;
    }

    return (<div className='tags-container'>
        {props.label !== undefined && props.adaptors.length>0 ? <h4>{props.vocabulary[props.label]}</h4>
            :(props.labelForEmpty !== undefined && props.adaptors.length === 0 ? <h4>{props.vocabulary[props.labelForEmpty]}</h4>:null)}
        {props.adaptors.map((adaptor,index)=>{
            let date : Date|undefined = expire(adaptor);
            if(adaptor instanceof RequestKV || adaptor instanceof SaveKV){
                const requestAdaptor : RequestKV|SaveKV = adaptor instanceof RequestKV ? adaptor as RequestKV : adaptor as SaveKV;
                return requestAdaptor.value ?
                    <Tooltip content={<p>{ date ? props.vocabulary[license_expiration] + ' ' +date :
                        props.deleteTooltip ? props.deleteTooltip : props.vocabulary[common_caption_26]}</p>}
                                key ={index}>
                        <Tag
                            intent={date ? Intent.DANGER : Intent.PRIMARY}
                            large={true}
                            className={'tag-element' +getRGBStyle(requestAdaptor.isValueAcceptable())}
                            onRemove={(e: React.MouseEvent<HTMLButtonElement>) =>{
                                onRemove(requestAdaptor);
                            }}>
                            {getTagString(adaptor.key)}
                        </Tag>
                    </Tooltip> :
                    <Tooltip content={<p>{props.addTooltip ? props.addTooltip : props.vocabulary[add_label]}</p>}
                             key ={index}>
                        <Tag
                            intent={props.intent ? props.intent :Intent.SUCCESS}
                            large={true}
                            className={'tag-element' +getRGBStyle(requestAdaptor.isValueAcceptable())}
                            interactive={true}
                            rightIcon='add'
                            onClick={() =>{
                                onRemove(requestAdaptor);
                            }}>
                            {getTagString(adaptor.key)}
                        </Tag>
                    </Tooltip>

            }
            return <Tag
                    key ={index}
                    large={true}
                    className={'tag-element' +getRGBStyle(adaptor.isNegative)}>{adaptor.key}</Tag>
        })}
    </div>)
};

const mapStateToProps = (state: AppState) => ({
    user : state.user,
    vocabulary : state.data.vocabulary
});
export default connect(
    mapStateToProps,
    {sendRequest}
)(TagRequestView);