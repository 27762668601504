import { ThunkAction } from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Action} from "redux";
import {setCoursePosition} from "../actions/settings";
import {FirebaseMessage} from "../model/common/FirebaseMessage";
import {firebaseSend} from "./firebase";
import {STORED_SETTINGS} from "../service/Literals";

export default(course : string, selectedModule : number, selectedLesson : number, uuid : string): ThunkAction<void, AppState, null, Action> => async (dispatch,getState) => {
    dispatch(setCoursePosition(course, selectedModule, selectedLesson, uuid));
    localStorage.setItem(STORED_SETTINGS, JSON.stringify(getState().settings));
    dispatch( firebaseSend(FirebaseMessage.getMyLessonMessages(course, uuid)));
}