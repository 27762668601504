import { ThunkAction } from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Action} from "redux";
import {receiveDownloads} from "../actions/data";
import {FullBucketDescription, initBuildListing} from "../service/S3";

export default(isBeta : boolean = false): ThunkAction<void, AppState, null, Action> => async dispatch => {
    const downloads : FullBucketDescription | undefined = await initBuildListing(isBeta);
    if(downloads !== undefined){
        dispatch(receiveDownloads(downloads));
    }
}