import {DataType} from "../actions/data";
import {Method} from "../model/Request";
import {FormAdaptor, IFormAdaptor} from "../adaptors/FormAdaptor";
import {Dlc} from "./Dlc";
import {KV, LinkKV, RequestKV, ValidatedRequestKv} from "../model/KV";
import {RequestData, SimpleRequestData} from "../adaptors/RequestData";
import {StringOptionsArrayAdaptor} from "../adaptors/FieldAdaptor";
import {
    add_user_course,
    add_user_dlc,
    authorization_disabled,
    authorization_enabled,
    decrease_counter,
    device_model,
    disable_authorization,
    disable_user,
    disabled_user,
    email_doesnt_exist,
    email_exists,
    email_not_verified,
    email_verified,
    enable_authorization,
    enable_user,
    enabled_user,
    entity_id,
    gpu_device_name,
    gpu_device_version,
    gpu_memory_size,
    invalid_email_limit,
    invalid_email_registration,
    memory_size,
    name_label,
    operation_system,
    processor_type,
    registration_date,
    software_product,
    username_label
} from "../text/Literals";
import {APPLICATION_URL} from "./ServerAddress";

export interface Hardware {
    id : string;
    deviceModel: string;
    deviceName: string;
    deviceType: string;

    operatingSystem: string;
    processorType: string;
    systemMemorySize: string;

    graphicsDeviceID: string;
    graphicsDeviceName: string;
    graphicsDeviceVersion: string;
    graphicsMemorySize: string;
    emailBounceAttempts : number;
}

export interface UserDeviceInfo extends  DeviceInfo{
    id : number;
    username : string;
}

export interface DeviceInfo{
    product : string;
    authorized : boolean;
    duplicatedId : string;
    token : string;
    refreshToken : string;
    save : string;
    settings : string;
    previousToken : string;
    deviceId : number;
}

export interface HardwareUsers extends Hardware{
    devices : UserDeviceInfo[]
}

export interface Device extends Hardware, DeviceInfo{

}
export interface Purchase {
    id : number;
    dlc : string;
    date : Date;
    price : number;
    seller : string;
    canceled : boolean;
    canceler ?: string;
    canceldate ?: Date;
}

export interface Player{
    username : string;
    enabled : boolean;
    registred : Date;
    verified : boolean;
    isBounced : boolean;
    hardwares : Device [];
    purchases : Purchase [];
}

export function getPlayerPurchaseInput(player : Player): RequestData  {
    return new PlayerAddPurchaseRequest(player);
}
export function getPlayerPurchaseCourseInput(player : Player): RequestData  {
    return new PlayerAddPurchaseRequest(player,true);
}

export function getPlayerPurchaseView(player ?:Player):KV[] {
    if(player === undefined) return [];
    const res : KV[] =[];
    for(let i=0; i<player.purchases.length;i++){
        res.push(player.purchases[i].canceled ?
            {key:player.purchases[i].dlc,value : false} :
            new RequestKV(player.purchases[i].dlc,true,DataType.Purchase,'Отменить',
                [player.username,player.purchases[i].id+''],Method.DELETE)
        )
    }
    return res;
}

export class PlayerAddPurchaseRequest extends SimpleRequestData{
    player : Player;
    onDataChanged ?: (adaptor: IFormAdaptor) => void;
    adaptor : IFormAdaptor;
    value ?: string;
    customDlc : boolean;

    constructor(player: Player,customDlc : boolean =false ) {
        super();
        this.player = player;
        this.customDlc = customDlc;
        this.adaptor = this.createFormAdaptor();
    }

    getFormAdaptor(): IFormAdaptor {
        return this.adaptor;
    }

    getMethod(): Method {
        return Method.POST;
    }

    message=() =>{
        return JSON.stringify({username : this.player.username, dlc : this.value});
    };

    setValue = (val : string)=>{
        this.value = val;
        if(this.onDataChanged === undefined) return;
        this.onDataChanged(this.createFormAdaptor());
    };

    createFormAdaptor=()=> {
        return new FormAdaptor(
            [new StringOptionsArrayAdaptor(this.customDlc ? add_user_course :add_user_dlc,this.setValue,this.value,
                this.customDlc ? Dlc.filterCustomValues :Dlc.filterValues,
                this.player.purchases.filter(purchase=>!purchase.canceled).map(purchase=>purchase.dlc),true,
                undefined,this.customDlc ? Dlc.getDlcContentDescriptors : Dlc.getDlcPurchaseDescriptors)],
            this)
    };

    getType(): DataType {
        return DataType.Purchase;
    }
}

export function playerToKeyValueList(player ?: Player) : KV[]{
    if( player === undefined) return  [];

    const enabled : RequestKV= player.enabled ?
        new RequestKV(enabled_user,player.enabled,DataType.DisablePlayer,disable_user,[player.username]):
        new RequestKV(disabled_user,player.enabled,DataType.EnablePlayer,enable_user,[player.username]);

    const verified = player.verified ?
        {key : email_verified, value : player.verified} :
        {key : email_not_verified, value : player.verified};

    const bounced = player.isBounced ?
        {key : email_doesnt_exist, value : ! player.isBounced} :
        {key : email_exists, value : ! player.isBounced};

    return [
        {key : username_label, value : player.username},
        {key : registration_date, value : new Date(player.registred).toLocaleDateString()},
        enabled, verified, bounced
    ];
}

export function deviceToKeyValueList(device : Device, username : string) : KV[] {
    let bounced : KV;
    if(device.emailBounceAttempts >= 3) {
        bounced = new ValidatedRequestKv(`${invalid_email_limit} ${device.emailBounceAttempts}`,
            device.emailBounceAttempts, DataType.DecreaseBounds, decrease_counter, val => {
                return (val as number) < 3
            }, [username, device.id]);
    } else {
        bounced= {key: invalid_email_registration, value :device.emailBounceAttempts};
    }

    const authorized = device.authorized ? new RequestKV(authorization_enabled,device.authorized,
        DataType.AuthorizeDevice,disable_authorization,[username, device.deviceId+''],Method.DELETE):
        new RequestKV(authorization_disabled,device.authorized,
            DataType.AuthorizeDevice,enable_authorization,[username, device.deviceId+''],Method.PUT);
    return [
        {key: entity_id, value : device.id},
        {key : name_label, value : device.deviceName},
        {key : device_model, value : device.deviceModel},
        {key: software_product, value : device.product},
        authorized,
        bounced,
    ];
}

export function deviceToFullList(device: Device, username : string):KV[] {
    return deviceToKeyValueList(device,username).concat(deviceToAdditionalValues(device));
}

export function deviceToAdditionalValues(device: Device) : KV[] {

    const res : KV[]= [
        {key: operation_system, value: device.operatingSystem},
        {key: processor_type, value : device.processorType},
        {key: memory_size, value : device.systemMemorySize},
        {key: gpu_device_name, value : device.graphicsDeviceName},
        {key: gpu_device_version, value : device.graphicsDeviceVersion},
        {key: gpu_memory_size, value : device.graphicsMemorySize},
    ];
    if(device.duplicatedId && device.duplicatedId.length > 0) res.push({key: 'Копия устройства', value : device.duplicatedId});
    return res;
}

export function deviceUserInfoKV(device : UserDeviceInfo, id : string) : KV[]{
    const authorized = device.authorized ? new RequestKV(authorization_enabled,device.authorized,
        DataType.AuthorizeDeviceHd,disable_authorization,[id, device.id+''],Method.DELETE):
        new RequestKV(authorization_disabled,device.authorized,
            DataType.AuthorizeDeviceHd,enable_authorization,[id, device.id+''],Method.PUT);
    return [
        new LinkKV(device.username,device.product, APPLICATION_URL),
        authorized,
    ];
}

export function hardwareKV(device : Hardware) : KV[]{
    return [
        {key: entity_id, value: device.id},
        {key: name_label, value: device.deviceName},
        {key: device_model, value: device.deviceModel},
        {key: operation_system, value: device.operatingSystem},
        {key: processor_type, value: device.processorType},
        {key: memory_size, value: device.systemMemorySize},
        {key: gpu_device_name, value: device.graphicsDeviceName},
        {key: gpu_device_version, value: device.graphicsDeviceVersion},
        {key: gpu_memory_size, value: device.graphicsMemorySize},
    ];
}

export enum DeviceType {
    Desktop = 'Desktop',
    Handheld = 'Handheld',
    Unknown = 'Unknown'
}

export function getDeviceType(device : Device) : DeviceType {
    switch (device.deviceType) {
        case DeviceType.Desktop:
            return DeviceType.Desktop;
        case DeviceType.Handheld:
            return DeviceType.Handheld;
        default:
            return DeviceType.Unknown;
    }
}
