import React, {FC, memo} from 'react';
import {CourseData, CourseRepresentation, decodeContent} from "../../model/courses/Course";
import {Button, Card, Icon} from "@blueprintjs/core";
import {Elevation} from "@blueprintjs/core/lib/esm/common/elevation";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {Text, Txt} from "../tools/Text";
import {InstrumentType} from "../../actions/instruments";
import useInstrument from '../../thunks/useInstrument';
import {connect, Provider} from "react-redux";
import {Entity} from "../../model/Entity";
import {do_study, free_course_added, free_label} from "../../text/Literals";
import {FreeDlcDescription} from "../../api/Dlc";
import {sendRequest} from "../../thunks/sendRequest";
import {Method, Request} from "../../model/Request";
import {DataType} from "../../actions/data";
import {AppState} from "../../store/configureStore";
import {AppToaster} from "../../service/toaster";
import store from "../../store/store";

interface CourseInfoProps {
    click : ()=>void;
    name ?: string;
    description ?: string;
}

interface CourseInfoBaseProps {
    data : CourseRepresentation;
    token : string;
    useInstrument ?: any;
    sendRequest ?: any;
}

const CourseInfo : FC<CourseInfoProps> = props => {
    return <Card interactive={true} elevation={Elevation.THREE} className='tiles-item tiles-container-vertical' onClick={props.click}>
        <div className='tiles-item'>
            <Icon intent={Intent.SUCCESS} icon='manual' className='tiles-one-third'/>
            <Text text={new Txt().txt(props.name)} className='tiles-item-row color-success'/>
        </div>
        <Text text={new Txt().txt(props.description)} className='tiles-one-third'/>
        {/*<Text text={new Txt().txt(props.data.owner)} className='tiles-item'/>*/}
        {props.children}
    </Card>
};

const ViewMyCourseInfo  : FC<CourseInfoBaseProps> = props => {
    function click() {
        props.useInstrument(InstrumentType.StudentsCourses,undefined,new Entity({id: courseData.id,label : name }));
    }
    const courseData : CourseData = props.data as CourseData;
    const content = decodeContent(courseData);
    const name :string = content ? content.name : '';
    const description : string = content ? content.description : '';
    return <CourseInfo click={click} name={name} description={description}>
        <Button
            intent={Intent.SUCCESS}
            icon='learning'
            className='tiles-one-third'
            onClick={click}>
            <Text text={new Txt().dict(do_study)}/>
        </Button>
    </CourseInfo>
};

const FreeCourseInfoView : FC<CourseInfoBaseProps> = props => {
    function click() {
        props.sendRequest(new Request(undefined,DataType.TakeFreeCourse+data.id,Method.GET,props.token),DataType.TakeFreeCourse,undefined,()=>{
            AppToaster.show({
                intent: "success" ,
                icon: "box",
                message:    <Provider store={store}> <Text text={new Txt().dict(free_course_added)}/></Provider>
            });
        });
    }
    const data : FreeDlcDescription = props.data as FreeDlcDescription;
    return <CourseInfo click={()=>{}} name={data.name} description={data.description}>
        <Button
            intent={Intent.DANGER}
            icon='box'
            className='tiles-one-third'
            onClick={click}>
            <Text text={new Txt().dict(free_label)}/>
        </Button>
    </CourseInfo>
};


export default connect((state : AppState)=>({token:state.user.token}),{useInstrument})(memo(ViewMyCourseInfo));
export const FreeCourseInfo =connect((state : AppState)=>({token:state.user.token}),{sendRequest})(memo(FreeCourseInfoView));