import {applyMiddleware, combineReducers, createStore} from 'redux';
import userReducer from '../reducers/user';
import thunkMiddleware from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import errorReducer from '../reducers/error';
import processReducer from '../reducers/process';
import instrumentReducer from '../reducers/instruments';
import dataReducer from '../reducers/data';
import settingsReducer from '../reducers/settings';
import {loadUser} from "../model/User";
import {IInstruments, Instruments, InstrumentType} from "../actions/instruments";
import {Settings} from "../actions/settings";
import toolsReducer from "../reducers/tools";
import {systemLanguage} from "../service/ClientInfo";
import {STORED_INSTRUMENTS, STORED_SETTINGS} from "../service/Literals";

const rootReducer = combineReducers({
    user : userReducer,
    error: errorReducer,
    process : processReducer,
    instruments :instrumentReducer,
    data : dataReducer,
    settings : settingsReducer,
    tools : toolsReducer
});

export type AppState = ReturnType<typeof rootReducer>;

function initState()  {
    const inst = localStorage.getItem(STORED_INSTRUMENTS);
    const settingsStr = localStorage.getItem(STORED_SETTINGS);
    let instrument : IInstruments;
    instrument = inst? JSON.parse(inst) : undefined;
    if (instrument && (instrument.type === InstrumentType.SchoolRegister ||
        instrument.type === InstrumentType.MySchool  ||
        instrument.type === InstrumentType.TrialShd)){

        instrument.type = InstrumentType.No
    }
    const settings : Settings = settingsStr ? JSON.parse(settingsStr) : {language: systemLanguage(), coursesPositions : {},productsToAnalyze :[]};
    if(settings.coursesPositions === undefined) settings.coursesPositions = {};
    if(settings.productsToAnalyze === undefined) settings.productsToAnalyze = [];
    return {user : loadUser(),
            settings,
            instruments : instrument ?  new Instruments(instrument!.type,instrument!.entity) : new Instruments(InstrumentType.No) };
}

export function configureStore() {
    // const middlewares = [thunkMiddleware];
    // const middleWareEnhancer = applyMiddleware(...middlewares);

    return createStore(
        rootReducer,
        initState(),
        composeWithDevTools(applyMiddleware(thunkMiddleware))
    );
}