import React, {FC} from 'react';
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {Instruments, InstrumentType} from "../../actions/instruments";
import {ITools,isToolActive} from "../../actions/tools";
import {Classes, Dialog, Drawer, Position} from "@blueprintjs/core";
import {closeTool} from "../../actions/tools";
import {getBackGroundStyle} from "../../actions/settings";

export interface ToolContainerProps {
    instruments : Instruments;
    tools : ITools;
    tool : InstrumentType;
    closeTool : typeof closeTool;
    position : Position | "center";
    local ?: boolean;
    mode ?: string;
    fullscreen ?: boolean;
}

const ToolContainer : FC<ToolContainerProps> = props => {
    return  (
        props.local ?
            <div className={getBackGroundStyle(props.mode)}>
                    {props.children}
            </div> :
            ( props.position !== "center" ?
                <Drawer
                    className={Classes.OVERLAY_SCROLL_CONTAINER}
                    size={props.fullscreen ? '100%':'95%'}
                    backdropClassName =' tool-top-content'
                    title={' '}
                    position={props.position}
                    isOpen={isToolActive(props.tool, props.instruments.type, props.tools)}
                    onClose={()=>{props.closeTool(props.instruments.type,props.tool)}}>
                    <div className="bp3-dialog-body" >
                        {props.children}
                    </div>
                </Drawer>:
                <Dialog
                    className={Classes.OVERLAY_SCROLL_CONTAINER}
                    isCloseButtonShown={false}
                    backdropClassName =' tool-top-content'
                    title={' '}
                    isOpen={isToolActive(props.tool, props.instruments.type, props.tools)}
                    onClose={()=>{props.closeTool(props.instruments.type,props.tool)}}
                >
                    <div className="bp3-dialog-body" >
                        {props.children}
                    </div>
                </Dialog>
            ));
};

const mapStateToProps = (state: AppState) => ({
    instruments : state.instruments,
    tools : state.tools,
    mode : state.settings.mode
});

export default connect(mapStateToProps,{closeTool})(ToolContainer);
