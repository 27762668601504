import React, { PureComponent} from "react";
import PostRequestForm from "../forms/PostRequestForm";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {DataType} from "../../actions/data";
import {Dlc, getDlcButtonAdapters, getDlcKeyValueList, IDlc} from "../../api/Dlc";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Button, Card, Elevation} from "@blueprintjs/core";
import KeyValueViewer from "../viewers/KeyValueViewer";
import FormButton from  "../forms/FormButton";
import {
    add_label,
    create_dlc,
    dlc_id_required,
    do_create_dlc,
    edit_dlc,
    edit_label
} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";
import {Txt} from "../tools/Text";

interface DlcEditorInstrumentProps {
    dlc ?: IDlc[];
    token: string;
    vocabulary : Vocabulary
}
interface DlcEditorState {
    dlc ?: Dlc;
}

class DlcEditorInstrument extends PureComponent<DlcEditorInstrumentProps,DlcEditorState>  {

    constructor(props: Readonly<DlcEditorInstrumentProps>) {
        super(props);
        this.state={dlc : undefined};
    }

    handleEditDlc =(dlc ?: IDlc)=>{
        if(dlc === undefined){
            this.setState({dlc});
            return;
        }
        this.setState({dlc : new Dlc(dlc.id,dlc.included,dlc.tier,dlc.archived, dlc.content, dlc.owner)})
    };

    handleCloseEdit=()=>{
        this.handleEditDlc(undefined);
    };

    render(){
        return (
            <div>
                {  this.state.dlc === undefined ?
                    <Button intent={Intent.SUCCESS} icon={"add"} onClick={() => {
                        this.handleEditDlc({id: '', included: null, tier: 0, archived: false, owner : undefined})
                    }}>{this.props.vocabulary[do_create_dlc]}</Button> : null
                }
                { this.state.dlc === undefined ? null :
                <PostRequestForm
                    default={true}
                    intent={Intent.WARNING}
                    url= {DataType.Dlc}
                    label={new Txt().dict(this.state.dlc.isNew ? create_dlc : edit_dlc)}
                    type={DataType.Dlc}
                    help={this.state.dlc.isNew ? this.props.vocabulary[dlc_id_required]: ''}
                    data={this.state.dlc}
                    method={this.state.dlc.getMethod()}
                    cancel={this.handleCloseEdit}
                    buttonText={this.state.dlc.isNew ? add_label : edit_label}
                /> }
                <Card interactive={false} elevation={Elevation.THREE} className='instruments-cards--space tiles-container'>
                    {this.props.dlc === undefined ? null :
                        this.props.dlc.map((dlc, index) =>(
                            <Card interactive={true} elevation={Elevation.ONE}  key={index} className='tiles-item form--item-container'>
                            <div className='form-item-element'>
                            {getDlcKeyValueList(dlc).map(kv=>(
                                <KeyValueViewer
                                    token={this.props.token}
                                    key={kv.key}
                                    kv={kv}/>
                            ))}
                            </div>
                            <div className='form-item-element form--button-container'>
                                {
                                    getDlcButtonAdapters(dlc, this.handleEditDlc).map((adaptor,index) =>
                                        (<FormButton
                                            className='form-button'
                                            adaptor={adaptor}
                                            token={this.props.token}
                                            key={index}/>) )
                                }
                            </div>
                        </Card>))}
                </Card>
            </div>
        );}

}
const mapStateToProps = (state: AppState) => ({
    dlc : state.data.dlc,
    token : state.user.token,
    vocabulary : state.data.vocabulary
});
export default connect(
    mapStateToProps
)(DlcEditorInstrument);