import {AuthResponse} from "../api/AuthResponse";
import {Classroom} from "../api/Classroom";
import {CourseData} from "./courses/Course";
import {updateIndex} from "../api/course/Entity";

export enum Role {
    ROLE_USER ='ROLE_USER',
    ROLE_SEED ='ROLE_SEED',
    ROLE_RESERVATION ='ROLE_RESERVATION',
    ROLE_PLAYER = 'ROLE_PLAYER',
    ROLE_EMPTY ='ROLE_EMPTY',
    ROLE_SCHOOL = 'ROLE_SCHOOL',
    ROLE_PARTNER = 'ROLE_PARTNER',
    ROLE_EDITOR = 'ROLE_EDITOR',
    ROLE_WRITER = 'ROLE_WRITER',
    ROLE_TRANSLATOR = 'ROLE_TRANSLATOR',
    ROLE_TEXTEDITOR = 'ROLE_TEXTEDITOR',
    ROLE_LEVEL_EDITOR = 'ROLE_LEVEL_EDITOR',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_MAIL_ROBOT = 'ROLE_MAIL_ROBOT',
    ROLE_ROOT = 'ROLE_ROOT',
    ROLE_SCHOOL_MANAGER = 'ROLE_SCHOOL_MANAGER'
}

export function createUserFromResponse(auth : AuthResponse) : User {
    const user : User = new User(auth.token);
    user.refreshToken = auth.refreshToken;
    user.authorities = auth.authorities;
    user.schools = auth.schools ?  [...auth.schools] : undefined;
    user.classrooms = auth.classrooms ? [...auth.classrooms] :  undefined;
    user.bounced = auth.bounced;
    user.verified = auth.verified;
    user.teachersCourses = auth.teachersCourses ?  [...auth.teachersCourses] : undefined;
    user.studentsCourses = auth.studentsCourses ? [...auth.studentsCourses] : undefined;
    return user;
}


export interface IUser extends AuthResponse{
    isLoggedIn: boolean;
    password: string;
    username: string;
    areCredentialsEmpty() : boolean;
}

export function copyUser(model : IUser) :User {
    const result : User= createUserFromResponse(model);
    result.username = model.username;
    result.password = model.password;
    result.isLoggedIn = model.isLoggedIn;
    return result;
}

export class User implements IUser{
    private _token: string;
    private _isLoggedIn: boolean;
    private _password: string;
    private _username: string;
    private _refreshToken: string;
    private _roles: Role  [];
    schools ?: string [];
    classrooms ?: Classroom [];
    verified ?: boolean;
    bounced ?:boolean;
    teachersCourses ?: CourseData[];
    studentsCourses ?: CourseData[];

    constructor(token: string) {
        this._token = token;
        this._isLoggedIn = token !== undefined && token.length > 0;
        this._username ='';
        this._password ='';
        this._refreshToken = '';
        this._roles = [];
        this.teachersCourses =[];
        this.studentsCourses =[];
    }

    isAdmin=():boolean =>{
      return this.bounced === undefined;
    };

    eraseCredentials(){
        this._username = '';
        this._password = '';
    }

    getSchoolsClassrooms =(school : string) : Classroom[]=>{
        if(this.classrooms === undefined)
            return [];
        return this.classrooms.filter(classroom=> classroom.school === school);
    };

    get token(): string {
        return this._token;
    }

    set setLoggedIn(value: boolean) {
        this._isLoggedIn = value;
    }
    set token(value: string) {
        this._token = value;
    }

    get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    set isLoggedIn(value: boolean) {
        this._isLoggedIn = value;
    }

    get password(): string {
        return this._password;
    }

    get username(): string {
        return this._username;
    }

    set password(value: string) {
        this._password = value;
    }

    set username(value: string) {
        this._username = value;
    }

    get refreshToken(): string {
        return this._refreshToken;
    }

    set refreshToken(value: string) {
        this._refreshToken = value;
    }

    get authorities(): Role[] {
        return this._roles;
    }

    set authorities(value: Role[]) {
        this._roles = value;
    }
    areCredentialsEmpty() : boolean{
        return this._password === undefined || this._username === undefined ||
            this._password.length === 0 || this.username.length === 0;
    }

    isPasswordNotMatched = (val: string): boolean =>{
        return this.areCredentialsEmpty() || this._password !== val;
    };

    getSchoolClassrooms = (school : string) : Classroom []=>{
        return this.classrooms === undefined ? [] : this.classrooms.filter(classroom => classroom.school === school);
    };

    getClassroomById = (id : number) : Classroom | undefined=>{
        if(this.classrooms === undefined) return  undefined;
        const filtered : Classroom [] = this.classrooms.filter(classroom => classroom.id === id);
        return filtered.length > 0 ? filtered[0] : undefined;
    };

    getTeachersCourseById=(id :string) :CourseData | undefined=>{
        if(this.teachersCourses === undefined) return  undefined;
        const filtered : CourseData [] = this.teachersCourses.filter(course => course.id === id);
        return filtered.length > 0 ? filtered[0] : undefined;
    };

    getStudentsCourseById=(id :string) :CourseData | undefined=>{
        if(this.studentsCourses === undefined) return  undefined;
        const filtered : CourseData [] = this.studentsCourses.filter(course => course.id === id);
        return filtered.length > 0 ? filtered[0] : undefined;
    };

    getCourseById=(id: string) :CourseData | undefined=>{
        const res = this.getStudentsCourseById(id);
        return res ? res : this.getTeachersCourseById(id);
    };

    onTeachersCourseEdit=(newCourse: CourseData) =>{
        updateIndex(newCourse,this.teachersCourses);
    };

    getSubscriptionIds =() : string[]=>{
        return getSubscriptionIds(this);
    };

    toAuth=() : AuthResponse =>{
        return {
            token: this.token,
            refreshToken: this.refreshToken,
            authorities: this.authorities,
            schools: this.schools === undefined ? undefined : [...this.schools],
            classrooms: this.classrooms === undefined ? undefined : [...this.classrooms],
            bounced: this.bounced,
            verified: this.verified,
            teachersCourses: this.teachersCourses === undefined ? undefined : [...this.teachersCourses],
            studentsCourses: this.studentsCourses === undefined ? undefined : [...this.studentsCourses],
        };
    };

    save=()=>{
        const STORED_AUTH ='authentication';
        localStorage.setItem(STORED_AUTH, JSON.stringify(this.toAuth()));
    };

    onGotStudentsCourse=(courseData: CourseData) =>{
        if(!this.studentsCourses){
            this.studentsCourses = [];
        }
        this.studentsCourses.push(courseData);
    };

    onTeachersCourseDelete=(id: string) =>{
        if(!this.teachersCourses) return;
        const index = this.teachersCourses.findIndex(course =>course.id === id);
        if(index <0)return;
        this.teachersCourses.splice(index,1);
    }
}

export function loadUser() : User {
    const STORED_AUTH ='authentication';
    const auth = localStorage.getItem(STORED_AUTH);
    return auth === null ? new User('') : createUserFromResponse(JSON.parse(auth));
}

export function getSubscriptionIds (auth : AuthResponse) : string[]{
    const res : string[] = [];
    if(auth.studentsCourses){
        res.push(...auth.studentsCourses.map(course=>course.id));
    }
    if(auth.teachersCourses){
        res.push(...auth.teachersCourses.map(course=>course.id).filter(id=>res.indexOf(id)<0));
    }
    return res;
}

export function getMyTeachersCoursesIds(auth : AuthResponse) : string[]{
    return auth.teachersCourses ? auth.teachersCourses.map(course=>course.id) : [];
}