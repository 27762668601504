import React,{FC} from "react";
import {Vocabulary} from "../../model/AppData";
import {convertSchool, SchoolUser} from "../../api/School";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {DataType} from "../../actions/data";
import {Txt} from "../tools/Text";
import {changes_saved, edit_label, edit_school} from "../../text/Literals";
import PostRequestForm from "../forms/PostRequestForm";
import {Method} from "../../model/Request";
import {IDlc} from "../../api/Dlc";
import {Software} from "../../api/admin/Software";
import {AppToaster} from "../../service/toaster";
import {EmbeddedPlayerViewer} from "../viewers/PlayerViewer";

interface SchoolApplicationProps{
    vocabulary : Vocabulary;
    schoolUser ?: SchoolUser
    tags ?: string[];
    dlc ?: IDlc [];
    software ?: Software[]
}

const SchoolEditor : FC<SchoolApplicationProps> = props => {
    if (props.schoolUser === undefined)
        return null;
    return <div>
        <PostRequestForm
            default={true}
            intent={Intent.PRIMARY}
            url= {DataType.School}
            label={new Txt().dict( edit_school)}
            type={DataType.School}
            help={''}
            data={convertSchool(props.schoolUser.school)}
            method={Method.PUT}
            buttonText={edit_label}
            ok ={()=>{AppToaster.show(
                {
                    intent: "primary",
                    message: props.vocabulary[changes_saved]
                }
            )}}
        />
        <EmbeddedPlayerViewer player = {props.schoolUser.user}/>
    </div>
}

const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary,
    schoolUser : state.data.schoolUser,
    software : state.data.software,
    tags : state.data.schoolHardwareTags,
    dlc : state.data.dlc})

export default connect(mapStateToProps)(SchoolEditor)

