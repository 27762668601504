import {ThunkAction} from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Action} from "redux";
import {sendRequest} from "./sendRequest";
import {Method, Request} from "../model/Request";
import {DataType} from "../actions/data";
import {FileTypes} from "../service/FileService";

export default(key : string, fileType :FileTypes = FileTypes.Image,onSuccess ?: (val : string|undefined)=>void): ThunkAction<void, AppState, null, Action> => async (dispatch,getState) => {
    const bucket : string = fileType === FileTypes.Video ? 'naura-custom-video' : 'naura-custom-files';
    const msg ={
        bucket,
        key
    };
    dispatch(sendRequest(new Request(JSON.stringify(msg),
        DataType.InvalidateContent,Method.DELETE,getState().user.token),DataType.InvalidateContent, getState().user,onSuccess));
}
