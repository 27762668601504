import { ThunkAction } from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Action} from "redux";
import {changeLanguage} from "../actions/settings";
import {sendRequest} from "./sendRequest";
import {Method, Request} from "../model/Request";
import {DataType} from "../actions/data";
import {STORED_SETTINGS} from "../service/Literals";

export default(language : string): ThunkAction<void, AppState, null, Action> => async (dispatch,getState) => {
    dispatch(changeLanguage(language));
    localStorage.setItem(STORED_SETTINGS, JSON.stringify(getState().settings));
    dispatch(sendRequest(new Request(undefined,
        DataType.Vocabulary+'/'+language,Method.GET,getState().user.token),DataType.Vocabulary, getState().user));
}

export const getVocabulary = (): ThunkAction<void, AppState, null, Action> => async (dispatch,getState) =>{
    dispatch(sendRequest(new Request(undefined,
        DataType.Vocabulary+'/'+getState().settings.language,Method.GET,getState().user.token),DataType.Vocabulary, getState().user));
};

