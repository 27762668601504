import React, {FC} from 'react';
import {LanguageAdaptor} from "../../adaptors/LanguageAdaptor";
import {Select} from "@blueprintjs/select";
import {Button, Intent, MenuItem} from "@blueprintjs/core";
import {FlagSVG} from "../viewers/FlagSVG";
import {ImageSize} from "../../adaptors/ImageAdaptor";
import {Vocabulary} from "../../model/AppData";

interface LanguagePickerProps {
    onSelect : (val : string)=>void;
    intent ?: Intent;
    options ?: string[];
    selected ?: string;
    size : ImageSize;
}
const defaultLanguages =['Russian','English','French','Japanese','German','Porto'];

const languageNames : Vocabulary= {
    Russian: "Русский",
    English: "English",
    French: "Français",
    Japanese: "日本語",
    German: "Deutsch",
    Korean: "한국어",
    Hirogana: "日本語",
    Porto: "Português"
}

const LanguagePicker : FC<LanguagePickerProps> = props => {
    const ids : string[] = props.options === undefined ? defaultLanguages : props.options;
    const values : LanguageAdaptor[] = ids.map(lang=>new LanguageAdaptor(lang));
    const selected = props.selected === undefined ? 0 : (ids.indexOf(props.selected) > 0 ? ids.indexOf(props.selected) : 0);
    const LanguageSelector = Select.ofType<LanguageAdaptor>();
    const size : ImageSize = props.size === undefined ? ImageSize.Tiny : props.size;
    return (
        <LanguageSelector
            items ={values}
            filterable = {false}
            onItemSelect = {adaptor=>{props.onSelect(adaptor.id);}}
            activeItem = {values[selected]}
            itemRenderer={(option, { handleClick, modifiers })=>
                <MenuItem
                    key = {option.id}
                    intent={modifiers.active ? props.intent : undefined}
                    active={modifiers.active}
                    text={<div className='tiles-container-fixed '>
                            <FlagSVG id={option.imageId} alt={option.id} size={size}/>
                            <span className='language-name'>
                                {languageNames[option.id]}
                            </span>
                        </div>}
                    onClick={handleClick}/>
            }
        >
            <Button
                minimal={true}
                className='inline'
                rightIcon="caret-down"
                title={values[selected].id}
                intent={props.intent}
            >
                <FlagSVG id={values[selected].imageId} alt={values[selected].id} size={size}/>
            </Button>
        </LanguageSelector>
    );
};

export default LanguagePicker;