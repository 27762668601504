import React, {Component} from "react";
import {
    convertSchool,
    getSchoolButtonsAdaptor,
    getSchoolMetricKVList,
    getSchoolShortKVList,
    ISchool,
    School,
    SchoolSearchRequest,
    SchoolsMetric
} from "../../api/School";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Button, Callout, Card, Elevation} from "@blueprintjs/core";
import KeyValueViewer from "../viewers/KeyValueViewer";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import PostRequestForm from "../forms/PostRequestForm";
import {DataType} from "../../actions/data";
import FormButton from "../forms/FormButton";
import {CSVLink} from 'react-csv';

import {
    download_selected_schools,
    edit_label,
    edit_school, email_list,
    get_school_metrics, hide_school_metrics,
    information_about_schools, invalid_or_bounced,
    register_label,
    register_school,
    school_search_help,
    schools_found,
    search_for_school,
    send_request
} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";
import RequestButton from "../RequestButton";
import {Method, Request} from "../../model/Request";
import {formatDate} from "../../service/formatDate";
import {Txt} from "../tools/Text";
import MailSendTool from "../tools/MailSendTool";
import {AppToaster} from "../../service/toaster";
import {isEmail} from "../../adaptors/FieldAdaptor";
import {MailResponse} from "../../api/MailRequest";
import MailSendResults from "../tools/MailSendResults";

interface SchoolInstrumentProps {
    schools ?:ISchool[];
    token : string;
    schoolsMetric ?: SchoolsMetric;
    vocabulary : Vocabulary
}

interface SchoolInstrumentState {
    school ?: School;
    schoolSearch ?: SchoolSearchRequest;
    hideSearch : boolean;
    expanded : Set<string>;
    hideMetrics : boolean;
    recipients : string [];
    invalidCount ?:number;
    mailResponse ?: MailResponse;
}

class SchoolInstrument extends Component<SchoolInstrumentProps,SchoolInstrumentState>{

    constructor(props: Readonly<SchoolInstrumentProps>) {
        super(props);
        this.state ={
            school : undefined,
            hideSearch : false,
            expanded : new Set<string>(),
            hideMetrics : false,
            recipients : []
        }
    }

    handleEditSchool=(school : ISchool | undefined)=>{
        if(school === undefined){
            this.setState({school});
            return;
        }

      this.setState({
          ...this.state,
            schoolSearch : undefined,
            school : convertSchool(school)})
    };

    handleMailTo =(recipients : string[]) =>{
        if (recipients.length === 0){
            AppToaster.show({
                icon: "warning-sign",
                intent: "danger",
                message: this.props.vocabulary[invalid_or_bounced]
            })

            return;
        }
        this.setState({...this.state, recipients})
    }

    handleMailToSelected =() =>{
        const count = this.props.schools!.length;
        const recipients = this.props.schools!
            .filter(school => !school.bounced)
            .map(school=> school.email)
            .filter(email => isEmail(email));

        const invalidCount = count - recipients.length ;
        if (invalidCount !== this.state.invalidCount) this.setState({...this.state, invalidCount })

        this.handleMailTo(recipients);
    }

    handleCloseMail = ()=>{
        this.setState({...this.state,recipients : [],invalidCount : undefined})
    }

    handleMailSent = (msg ?: string)=>{
        console.log(msg);
        if (msg) {
            this.setState({...this.state, mailResponse: JSON.parse(msg)})
        }
        this.handleCloseMail();
    }

    handleCloseMailResult = ()=>{
        this.setState({...this.state,mailResponse : undefined})
    }

    handleCreateSchool =()=>{
      this.handleEditSchool({
          username :'',
          name : '',
          city : '',
          address :'',
          email :'',
          phone : '',
          devices : 10,
          master : false,
          additionalComputers :0,
          description : 'RUS',
          region : '',
          contact : '',
          position : '',
          licenses : ['school','robot_key']
      })  ;
    };

    startSearch=()=>{
        this.setState({
            ...this.state,
            schoolSearch : new SchoolSearchRequest()
        })
    };

    stopSearch=()=>{
        this.setState({
            ...this.state,
            schoolSearch : undefined
        })
    };

    hideSearch=(hideSearch:boolean)=>{
        this.setState({
            ...this.state,
            hideSearch
        })
    };

    handleCloseEdit=()=>{
        this.handleEditSchool(undefined);
    };

    isExpanded =(school: ISchool):boolean => {
        return this.state.expanded.has(school.username)
    };

    handleExpand=(school : ISchool) =>{
      if (this.isExpanded(school)){
          this.state.expanded.delete(school.username);
      } else{
          this.state.expanded.add(school.username);
      }

      this.setState({
          ...this.state
      })
    };
    handleSave=()=> {
        if(this.props.schools !== undefined && this.props.schools.length === 1){
            this.handleEditSchool(this.props.schools[0]);
        }
    };

    handleHideMetrics =()=>{
        this.setState({...this.state,hideMetrics : true})
    };

    handleShowMetrics =()=>{
        this.setState({...this.state,hideMetrics : false})
    };
    render(){
        return <Card interactive={false} elevation={Elevation.ZERO} className='horizontal-margin'>
            <div className='tiles-container-adjustable'>
                {  this.state.school === undefined ?
                    <Button
                        intent={Intent.SUCCESS}
                        icon={"add"}
                        className='tiles-one-third'
                        onClick={() => {
                        this.handleCreateSchool()
                    }}>{this.props.vocabulary[register_school]}</Button> : null
                }
                {  this.state.schoolSearch === undefined ?
                    <Button
                        intent={Intent.PRIMARY}
                        icon={"geosearch"}
                        className='tiles-one-third'
                        onClick={this.startSearch}>
                    {this.props.vocabulary[search_for_school]}</Button> : null
                }{ this.props.schoolsMetric && ! this.state.hideMetrics ?
                <Button
                    intent={Intent.WARNING}
                    icon={"cross"}
                    className='tiles-one-third'
                    onClick={this.handleHideMetrics}>
                    {this.props.vocabulary[hide_school_metrics]}</Button> :
                <RequestButton
                    intent={Intent.WARNING}
                    icon='office'
                    type={DataType.School}
                    disabled={false}
                    className='tiles-one-third'
                    onSuccess={this.handleShowMetrics}
                    request={new Request(undefined, DataType.School + formatDate(new Date()), Method.GET, this.props.token)}>
                    {this.props.vocabulary[get_school_metrics]}
                </RequestButton>
            }
            </div>
            { this.state.schoolSearch === undefined ? null :
                <PostRequestForm
                    default={true}
                    intent={Intent.SUCCESS}
                    url= {DataType.SchoolAll}
                    label={new Txt().dict(search_for_school)}
                    type={DataType.SchoolAll}
                    help={school_search_help}
                    data={this.state.schoolSearch}
                    method={this.state.schoolSearch.getMethod()}
                    cancel={this.stopSearch}
                    buttonText={send_request}
                /> }

            { this.state.school === undefined ? null :
                <PostRequestForm
                    default={true}
                    intent={Intent.PRIMARY}
                    url= {DataType.School}
                    label={new Txt().dict(this.state.school.isNew ? register_school : edit_school)}
                    type={this.state.school.getType()}
                    help={''}
                    data={this.state.school}
                    method={this.state.school.getMethod()}
                    cancel={this.handleCloseEdit}
                    buttonText={this.state.school.isNew ? register_label : edit_label}
                    ok ={this.handleSave}
                /> }
                {this.props.schools === undefined ? null :
                    <Card interactive={false} elevation={Elevation.THREE} className='instruments-cards--space tiles-container'>
                        <div className='form-item-row form--button-container'>
                            <Callout intent={Intent.PRIMARY}
                                     icon='office'
                                     className='card-caption bp3-text-large '
                                     title={this.props.vocabulary[schools_found]+" " +this.props.schools.length}
                            >
                                {this.props.schools.length === 0 ? null :
                                    <div >
                                        <CSVLink data={this.props.schools} className ='inline common-margin'>
                                            <Button
                                                icon='import'
                                                intent={Intent.PRIMARY}
                                                onClick={()=>{}}>
                                                {this.props.vocabulary[download_selected_schools]}
                                            </Button>
                                        </CSVLink>
                                        <Button
                                            className ='inline common-margin'
                                            icon='envelope'
                                            intent={Intent.PRIMARY}
                                            onClick={this.handleMailToSelected}>
                                            {this.props.vocabulary[email_list]}
                                        </Button>
                                    </div>
                                }
                            </Callout>

                            {   this.state.hideSearch ?
                                <Button
                                    className='card-caption-button'
                                    icon='expand-all'
                                    intent={Intent.SUCCESS}
                                    onClick={()=>{this.hideSearch(false)}}/>
                                    :
                                <Button
                                    className='card-caption-button'
                                    icon='collapse-all'
                                    intent={Intent.DANGER}
                                    onClick={()=>{this.hideSearch(true)}}/>
                            }
                        </div>
                        {   this.state.hideSearch ? null :
                            this.props.schools.map((school, index) =>(
                            <Card interactive={true}
                                  elevation={Elevation.ONE}
                                  key={index}
                                  className={this.isExpanded(school) ? 'form-item-row form--item-container--columns':'tiles-item form--item-container'}>

                                    {getSchoolShortKVList(school,this.isExpanded(school)).map(kv=>(
                                        <div className='form-item-element' key={kv.key}>
                                        <KeyValueViewer
                                            token={this.props.token}
                                            kv={kv}/>
                                        </div>
                                    ))}
                                <div className={(this.isExpanded(school) ? 'full-row' : 'form-item-row' ) + ' form--center-button-container'}>
                                    {
                                        getSchoolButtonsAdaptor(school,
                                            this.handleEditSchool,
                                            this.handleExpand,
                                            this.isExpanded(school),
                                            this.handleMailTo).map((adaptor,index) =>
                                            <FormButton
                                                className='form-button-4'
                                                adaptor={adaptor}
                                                token={this.props.token}
                                                key={index}/>)
                                    }
                                </div>
                            </Card>))
                        }
                    </Card>
                }
            {this.props.schoolsMetric === undefined || this.state.hideMetrics ? null :
                <Callout
                    className={'instruments-cards--space '}
                    intent={Intent.SUCCESS}
                    icon='office'
                    title={this.props.vocabulary[information_about_schools]}
                ><div className={'tiles-container'}>
                    {getSchoolMetricKVList(this.props.schoolsMetric).map(kv=>(
                        <div className='tiles-one-third' key={kv.key}>
                            <KeyValueViewer
                                token={this.props.token}
                                kv={kv}/>
                        </div>
                    ))}
                </div>

                </Callout>}
            <MailSendTool
                recipients={this.state.recipients}
                close={this.handleCloseMail}
                onSuccess={this.handleMailSent}
                invalidCount={this.state.invalidCount}
            />
            {this.state.mailResponse ?
                <MailSendResults
                    res={this.state.mailResponse}
                    close={this.handleCloseMailResult} />
                    : null}
        </Card>
    }

}

const mapStateToProps = (state: AppState) => ({
    schools : state.data.schools,
    schoolsMetric : state.data.schoolsMetric,
    token : state.user.token,
    vocabulary : state.data.vocabulary
});
export default connect(
    mapStateToProps
)(SchoolInstrument);