import React, {Component} from 'react';
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {
    Device, deviceToKeyValueList, DeviceType, getDeviceType, Player,
    playerToKeyValueList, deviceToFullList, getPlayerPurchaseInput, getPlayerPurchaseView, getPlayerPurchaseCourseInput
} from "../../api/Player";
import KeyValueViewer from "./KeyValueViewer";
import {Button, Card, Elevation, Tooltip} from "@blueprintjs/core";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {RequestData} from "../../adaptors/RequestData";
import PostRequestForm from "../forms/PostRequestForm";
import {DataType} from "../../actions/data";
import TagRequestView from "../forms/TagRequestView";
import {
    add_label,
    add_player_dlc,
    cancel_purchase, desktop_label,
    add_user_dlc, handheld_label,
    no_players_dlc,
    players_dlc,
    select_dlc, show_less, show_more, unknown_device_type, select_course_dlc, add_player_course, add_user_course
} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";
import {Txt} from "../tools/Text";

interface PlayerViewerProps {
    player ?: Player;
    token : string;
    vocabulary : Vocabulary
}
interface PlayerViewerState {
    expanded : boolean;
    showAddPurchase : boolean;
    showAddCustomPurchases : boolean;
}

class PlayerViewer extends Component<PlayerViewerProps,PlayerViewerState>{
    purchaseRequestGenerator ?: RequestData;
    constructor(props: Readonly<PlayerViewerProps>) {
        super(props);
        if(props.player !== undefined) {this.purchaseRequestGenerator = getPlayerPurchaseInput(props.player);}
        this.state={
            expanded : false,
            showAddPurchase : false,
            showAddCustomPurchases : false
        }
    }

    getKeyValueViews = ()=>{
        return playerToKeyValueList(this.props.player).map(kv =>
            <div className='tiles-item-sizable' key={kv.key}>
            <KeyValueViewer
                token={this.props.token}
                inline={true}
                kv={kv}/></div>);
    };

    getAddPurchaseForm =(player : Player)=>{
        const purchaseRequestGenerator = getPlayerPurchaseInput(player);
        const purchaseCourseRequestGenerator = getPlayerPurchaseCourseInput(player);
        return purchaseRequestGenerator === undefined ? undefined :
            <Card interactive={true} elevation={Elevation.TWO} >
                <Button icon='shop'  intent={Intent.WARNING} minimal={true} className='card--title-icon'/>
                <TagRequestView
                    deleteTooltip={cancel_purchase}
                    label={players_dlc}
                    labelForEmpty={no_players_dlc}
                    adaptors={getPlayerPurchaseView(this.props.player)}/>
                {  this.state.showAddPurchase && this.state.showAddCustomPurchases ? null :
                    <div className='bp3-dialog-footer-actions'>
                        { !this.state.showAddPurchase ?
                        <Button icon='add' intent={Intent.SUCCESS} onClick={this.toggleAddPurchase}>
                                {this.props.vocabulary[add_user_dlc]}
                            </Button> :null
                        }
                        { !this.state.showAddCustomPurchases ?
                        <Button icon='add' intent={Intent.SUCCESS} onClick={this.toggleAddCourse}>
                            {this.props.vocabulary[add_user_course]}
                        </Button>:null
                        }
                    </div>
                }
                { this.state.showAddPurchase ?
                <PostRequestForm
                    intent={Intent.SUCCESS}
                    url= {DataType.Purchase}
                    label={new Txt().dict(add_player_dlc)}
                    type={DataType.Purchase}
                    help={select_dlc}
                    data={purchaseRequestGenerator}
                    method={purchaseRequestGenerator.getMethod()}
                    buttonText={add_label}
                    persistent={true}
                    cancel={()=>this.handleShowAddPurchase(false)}
                    ok = {()=>this.handleShowAddPurchase (false)}
                /> : null}
{ this.state.showAddCustomPurchases ?
                <PostRequestForm
                    intent={Intent.SUCCESS}
                    url= {DataType.Purchase}
                    label={new Txt().dict(add_player_course)}
                    type={DataType.Purchase}
                    help={select_course_dlc}
                    data={purchaseCourseRequestGenerator}
                    method={purchaseCourseRequestGenerator.getMethod()}
                    buttonText={add_label}
                    persistent={true}
                    cancel={()=>this.handleShowAddCustomPurchase(false)}
                    ok = {()=>this.handleShowAddCustomPurchase (false)}
                /> : null}
            </Card>
    };

    handleExpand=()=>{
        this.setState({
            ...this.state,
            expanded: !this.state.expanded
        });
    };
    handleShowAddPurchase=(showAddPurchase :boolean)=>{
        this.setState({
            ...this.state,
            showAddPurchase
        });
    };
    handleShowAddCustomPurchase=( showAddCustomPurchases:boolean)=>{
        this.setState({
            ...this.state,
            showAddCustomPurchases
        });
    };
    toggleAddPurchase=()=>{
        this.setState({
            ...this.state,
            showAddPurchase :! this.state.showAddPurchase
        });
    };
    toggleAddCourse=()=>{
        this.setState({
            ...this.state,
            showAddCustomPurchases :! this.state.showAddCustomPurchases
        });
    };

    getDevices = ()=>{
        if (this.props.player === undefined)
            return null;
        const devices : Device[] = this.props.player.hardwares;
        const username :string = this.props.player.username;
        if(devices === undefined || devices.length === 0)return null;
        return<div>
            {devices.map((device,index) =>
                {
                    const deviceType :DeviceType =getDeviceType(device);
                    const iconIntent : Intent = device.authorized ? Intent.SUCCESS :
                        (device.duplicatedId != null && device.duplicatedId.length >0 ? Intent.WARNING : Intent.DANGER);
                    const icon = deviceType === DeviceType.Desktop ? 'desktop' :(deviceType === DeviceType.Handheld ? 'mobile-phone': 'unknown-vehicle');
                    const tooltip = deviceType === DeviceType.Desktop ? desktop_label :(deviceType === DeviceType.Handheld ? handheld_label: unknown_device_type);

                    return(
                 <Card key={index} interactive={true} elevation={Elevation.TWO} >
                     <Tooltip content={tooltip} className='card--title-icon'>
                        <Button icon={icon}  intent={iconIntent} minimal={true}/>
                     </Tooltip>
                     {!this.state.expanded ?
                     <div className='instruments-cards tiles-container-space'>
                         {deviceToKeyValueList(device,username).map(kv =>
                             <div className='tiles-item-sizable' key={kv.key}>
                             <KeyValueViewer
                                 token={this.props.token}
                                 kv={kv}/></div>)}
                     </div> :null}
                     {this.state.expanded ? <div className='instruments-cards tiles-container-space'>
                         {deviceToFullList(device,username).map(kv =>
                             <div className='tiles-item-sizable' key={kv.key}>
                             <KeyValueViewer
                                 token={this.props.token}
                                 inline={true}
                                 kv={kv}/></div>)}
                     </div> : null}

                     <Tooltip content={this.state.expanded ? show_less : show_more} className='card--title-icon'>
                         <Button icon={this.state.expanded ? 'ban-circle' : 'add'}
                                 intent={Intent.SUCCESS}
                                 minimal={true}
                                 onClick={this.handleExpand}/>
                     </Tooltip>
                 </Card>
                )})}
        </div>;
    };
    render(){
        // console.log(this.props.player)
        if(this.props.player === undefined) return null;
        return <div>
                    <Card interactive={true} elevation={Elevation.THREE} className='instruments-cards--space'>
                        <Button icon='user'  intent={this.props.player.enabled ? Intent.SUCCESS : Intent.DANGER} minimal={true} className='card--title-icon'/>
                        <div className='tiles-container-space'>
                            {this.getKeyValueViews()}
                        </div>
                    </Card>
                    {this.getAddPurchaseForm(this.props.player)}
                    {this.getDevices()}
                </div>;
    }
}

const mapStateToProps = (state: AppState) => ({
    player : state.data.player,
    token : state.user.token,
    vocabulary : state.data.vocabulary
});

export const EmbeddedPlayerViewer = connect(
    (state: AppState) => ({
            token : state.user.token,
            vocabulary : state.data.vocabulary
        }))(PlayerViewer)

export default connect(mapStateToProps)(PlayerViewer);
