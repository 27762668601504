import {SetCoursePositionAction, SetStringAction, Settings, SettingsAction, SettingsType} from "../actions/settings";
import {systemLanguage} from "../service/ClientInfo";

export default function (settings: Settings={language:systemLanguage(),coursesPositions : {}, productsToAnalyze : [] },action :SettingsAction):Settings {
    let productsToAnalyze : string[];
    let act : SetStringAction;
    switch (action.type) {
        case SettingsType.SetLanguage:
            const setAction :SetStringAction = action as SetStringAction;
            return {...settings,
                    language : setAction.value
                    };
        case SettingsType.SetMode:
            const modeAction :SetStringAction = action as SetStringAction;
            return {...settings,
                mode : modeAction.value
            };
        case SettingsType.SetCoursePosition:
            const posAction : SetCoursePositionAction = action as SetCoursePositionAction;
            const coursesPositions = {...settings.coursesPositions};
            coursesPositions[posAction.value]={selectedModule : posAction.selectedModule, selectedLesson :posAction.selectedLesson, uuid : posAction.uuid};
//            console.log( coursesPositions[posAction.value]);
            return {...settings, coursesPositions};
        case SettingsType.AddProductToAnalyze:
            act = action as SetStringAction;
            productsToAnalyze = [...settings.productsToAnalyze];
            productsToAnalyze.push(act.value);
            return {...settings,productsToAnalyze};

         case SettingsType.RemoveProductToAnalyze:
            act = action as SetStringAction;
            productsToAnalyze = [...settings.productsToAnalyze];
            productsToAnalyze.splice(productsToAnalyze.indexOf(act.value),1);
            return {...settings,productsToAnalyze};

         case SettingsType.SetRegistrationFirst:
            return {...settings,registrationFirst : true};

         case SettingsType.SetLoginFirst:
            return {...settings,registrationFirst : false};

        default : return  settings;
    }
}