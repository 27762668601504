import {ThunkAction} from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Action} from "redux";
import {
    firebaseLogin,
    firebaseUsername,
    firestore,
    getMyReviewCount,
    getReviewReference,
    query,
    TIME,
    TYPE
} from "../service/Firebase";
import {
    FirebaseCourseData,
    FirebaseMessage,
    FirebaseMessageContent,
    FirebaseMessageType,
    FirebaseSignedHomework
} from "../model/common/FirebaseMessage";
import {AuthResponse} from "../api/AuthResponse";
import {
    DataType,
    receiveFirebaseMessages,
    receiveFirebaseStudentsHomework,
    receiveReviewList, receiveReviewListRemoves,
    receiveSubscribers
} from "../actions/data";
import {Vocabulary} from "../model/AppData";
import {firebaseSend} from "./firebase";
import {COURSES, REQUEST, SUBSCRIBERS} from "../service/Literals";
import {AppToaster} from "../service/toaster";
import {
    course_label,
    homework_was_sent,
    message_was_sent,
    new_homework_received,
    new_messages_received,
    new_students_registered, review_courses_added, review_courses_changed,
    review_label
} from "../text/Literals";
import {dispatchOpenCourse} from "../store/store";
import {getCourseName} from "../model/courses/Course";
import {CourseStatuses, ICourseStatus} from "../model/courses/CourseReviewStatus";
import {sendRequest} from "./sendRequest";
import {Method, Request} from "../model/Request";

const ADDED = "added";
const MODIFFIED = 'modified';
export default(auth: AuthResponse): ThunkAction<void, AppState, null, Action> => async (dispatch, getState) => {

    function getCourseCaption(topic : string) :string{
        const name =getCourseName(getState().user.getCourseById(topic));
        return getState().data.vocabulary[course_label]+": "+name + ".";
    }

    await firebaseLogin(auth.token);
    FirebaseMessage.getAllMessages(auth,true)
        .forEach(msg => dispatch(firebaseSend(msg)));
    FirebaseMessage.getAllMessages(auth)
        .forEach(msg=>
        {
            const collection = msg.type === FirebaseMessageType.NewSubscribers || msg.type === FirebaseMessageType.Subscribers ?
                firestore
                    .collection(COURSES)
                    .doc(msg.topic)
                    .collection(SUBSCRIBERS)
                :
                firestore
                    .collection(msg.topic);
            query(collection,msg)
            .onSnapshot((snapshot)=>
            {
                switch (msg.query!.dataType) {
                    case DataType.FirebaseMessages:
                        console.log("got message snap");
                        const res :FirebaseCourseData = [];
                        const hw : FirebaseSignedHomework[] = [];
                        snapshot.docChanges().forEach(change=>
                        {
                            if(change.type === "added" || change.type === 'modified'){
                                const message = change.doc.data() as FirebaseMessageContent;
                                if(message.type === FirebaseMessageType.MyHomework){
                                    const  homework : Vocabulary = change.doc.data() as Vocabulary;
                                    const time : number = +homework[TIME];
                                    delete homework[TIME];
                                    delete homework[TYPE];
                                    hw.push({
                                        student : change.doc.id,
                                        homework,
                                        time
                                    })
                                }else{
                                    message.topic = msg.topic;
                                    message.id = change.doc.id;
                                    message.new = true;
                                    res.push(message)
                                }

                            }
                        });
                        if(res.length >0) {
                            console.log(res);
                            const myMessages = res.filter(m=>m.user ===  firebaseUsername());
                            const otherMessages = res.filter(m=>m.user !==  firebaseUsername());
                            if(otherMessages.length >0) {
                                AppToaster.show({
                                    icon: "comment",
                                    message: getState().data.vocabulary[new_messages_received] +
                                        otherMessages.map(m => m.user + ": " + m.content).join(",") + ". " + getCourseCaption(msg.topic)
                                });
                            }
                            if(myMessages.length >0) {
                                AppToaster.show({
                                    icon: "comment",
                                    intent: "warning",
                                    message: getState().data.vocabulary[message_was_sent]
                                });
                            }

                            dispatch(receiveFirebaseMessages(msg.topic,res))
                        }
                        if(hw.length >0) {
                            console.log(hw);
                            const myHw= hw.filter(m=>m.student ===  firebaseUsername());
                            const otherHw = hw.filter(m=>m.student !==  firebaseUsername());
                            if(otherHw.length > 0) {
                                AppToaster.show({
                                    intent: "primary",
                                    icon: "learning",
                                    message: getState().data.vocabulary[new_homework_received] +
                                        otherHw.map(h => h.student).join(",") + ". " + getCourseCaption(msg.topic),
                                    timeout : 0,
                                    action : {
                                        text : getState().data.vocabulary[review_label],
                                        icon : 'eye-open',
                                        onClick :()=>{
                                            console.log(msg.topic);
                                            dispatchOpenCourse(msg.topic);
                                        }
                                    }
                                });
                            }
                            if(myHw.length > 0) {
                                AppToaster.show({
                                    intent: "warning",
                                    icon: "learning",
                                    message: getState().data.vocabulary[homework_was_sent],
                                });
                            }
                            hw.sort((record1,record2)=>record2.time- record1.time);
                            dispatch(receiveFirebaseStudentsHomework(msg.topic,hw))
                        }
                        break;
                    case DataType.FirebaseSubscribers:
                        console.log("got subscribers snap");
                        const s : string[] = [];
                        snapshot.docChanges().forEach(change=>
                        {
                            if(change.type === ADDED || change.type === MODIFFIED){
                                s.push(change.doc.id)
                            }
                        });
                        if(s.length >0) {
                            console.log(s);
                            AppToaster.show({
                                intent : "success",
                                icon : "new-person",
                                message: getState().data.vocabulary[new_students_registered] +
                                            s.join(",")+". "+getCourseCaption(msg.topic),
                                timeout : 0
                            });
                            dispatch(receiveSubscribers(msg.topic,s))
                        }
                        break;
                    default:
                        console.log("Unknown firebase request");
                }


            },error => {

            })
        });

        const  reviewRef = getReviewReference();
        reviewRef
            .get()
            .then(querySnap=>{
                reviewRef.onSnapshot(snapshot => {
                    const updated : CourseStatuses = {};
                    const deleted : CourseStatuses = {};
                    let added : number = 0;
                    let changed : number =0;
                    snapshot.docChanges().forEach(change=>{
                        if(change.type === ADDED || change.type === MODIFFIED){
                            const status = change.doc.data() as ICourseStatus;
                            updated[change.doc.id] = status;
                            if(status && status.status === REQUEST) {
                                if (change.type === ADDED) {
                                    added++;
                                } else {
                                    changed--;
                                }
                            }
                        }else {
                            deleted[change.doc.id] = change.doc.data() as ICourseStatus
                        }
                    });
                    if(added >0){
                        AppToaster.show({
                            intent: "success",
                            icon: "learning",
                            message: getState().data.vocabulary[review_courses_added],
                        });
                    }
                    if(changed >0){
                        AppToaster.show({
                            intent: "primary",
                            icon: "learning",
                            message: getState().data.vocabulary[review_courses_changed],
                        });
                    }
                    processUpdatedValues(updated);
                    dispatch(receiveReviewListRemoves(deleted));
                });
                const res : CourseStatuses = {};
                querySnap.forEach(doc=>{
                    res[doc.id] = doc.data() as ICourseStatus
                });
                processUpdatedValues(res);
            })
            .catch(e=>{
                //ignored - this user just don't have permission
            });

    function processUpdatedValues(res : CourseStatuses) {
        dispatch(receiveReviewList(res));
        if (getMyReviewCount(res) > 0) {
            dispatch(sendRequest(new Request(undefined, DataType.GetReviews, Method.GET, getState().user.token), DataType.GetReviews))
        }
    }
}
