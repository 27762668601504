import {IRequest, Method} from "../model/Request";
import {ExceptionType} from "../actions/error";
import {UploadNotificator} from "../components/tools/UploadFileDialog";

export const DEFAULT_CONTENT_TYPE = "application/json; charset=utf8";

export interface ConnectionResponse {
    status : number;
    message : string ;
}
export const DEFAULT_TIME_OUT= 60000;
export function sendAsync(method : Method, url : string, message : BodyInit|undefined = undefined,
                   auth : string|undefined = undefined, contentType : string = DEFAULT_CONTENT_TYPE,
                          timeout : number = DEFAULT_TIME_OUT, onUpload : UploadNotificator|undefined = undefined): Promise<ConnectionResponse> {
    // console.log(url);
    // console.log(message);
    return new Promise(function (resolve, reject) {
        const xhttp = new XMLHttpRequest();
        xhttp.open(Method[method], url, true);
        xhttp.setRequestHeader("Content-type", contentType);
        xhttp.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhttp.setRequestHeader('Access-Control-Allow-Headers', '*');
        xhttp.setRequestHeader('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
        if (auth) {
            xhttp.setRequestHeader('Authorization', auth as string);
        }
        xhttp.timeout = timeout;
        xhttp.ontimeout = ()=>{
            reject({
               status : ExceptionType.TimeOut,
                message: ''
            });
        };
        if(onUpload) {
            xhttp.upload.onloadstart = (e: ProgressEvent) => {
                onUpload.onStart(e.loaded,e.total);
            };
            xhttp.upload.onprogress = (e: ProgressEvent) => {
                onUpload.onProgress(e.loaded,e.total)
            };
            xhttp.upload.onloadend = (e: ProgressEvent) => {
                onUpload.onEnd(e.loaded,e.total)
            };
        }
        xhttp.onreadystatechange = () => {
            if (xhttp.readyState === xhttp.DONE) {
                if (xhttp.status < 200 || xhttp.status >= 400) reject({
                    status: xhttp.status,
                    message: xhttp.responseText
                });
                else resolve({
                    status: xhttp.status,
                    message: xhttp.responseText
                });
            }
        };
        try {
            if (method !== Method.GET && message !== undefined) {
                xhttp.send(message);
            } else {
                xhttp.send();
            }
        } catch (e) {
            console.log(e);
            reject({status: 0, message: ''});
        }
    })
}

export function send(request  : IRequest) : Promise<ConnectionResponse>{
        const method = request.getMethod();
        const url = request.getUrl();
        const auth = request.getAuthorization();
        const message = request.getMessage();
        const timeout = request.timeout;

    return sendAsync(method, url,message, auth, DEFAULT_CONTENT_TYPE, timeout);
}