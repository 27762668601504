/*
   Interface between data and view form field
 */
import {AppData, Vocabulary} from "../model/AppData";
import {EncodedObject} from "../model/EncodedObject";
import {IconName} from "@blueprintjs/icons";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import {StringArrayElement} from "../model/StringArrayElement";
import {KV, SaveKV} from "../model/KV";
import {EmbeddedObject} from "../model/EmbededObject";
import {
    email_bounced,
    key_not_found,
    not_empty_field,
    not_valid_email,
    not_valid_phone,
    nothing_selected,
    number_format_required,
    uniq_value_required,
    wrong_format
} from "../text/Literals";
import {mapTag} from "../text/mapTag";
import {DataType} from "../actions/data";
import {IExpire} from "../api/School";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {isValid, toScreenFormat, toStorageFormat} from "../service/PhoneTextUtil";

export interface FormFieldAdaptor<T> {
    // Display title
    name : string;
    // Hint on hoover
    hint ?: string;
    // Display value
    value ?: T;
    // If true can't be edited
    disabled :boolean;
    // save value when user input changes
    set(t : T) : void;
    // returns error if value is incorrect
    errorMessage() : string|undefined;
    // connects adaptor to all application data
    // used for getting list of options etc
    setData(appData : AppData) : void;
    //when field is saved should commit changes
    autoCommit ?: boolean;
}

export interface OptionsFieldAdaptor<T> extends FormFieldAdaptor<T>{
    options : T[];
}

export class SimpleFieldAdaptor<T> implements FormFieldAdaptor<T>{
    name: string;
    disabled: boolean;
    hint ?: string;
    value ?: T;
    _set :(val : T) => void;

    constructor(name: string, set: (val: T) => void, value: T|undefined=undefined, disabled: boolean = false, hint: string|undefined = undefined) {
        this.name = name;
        this.disabled = disabled;
        this.hint = hint;
        this.value = value;
        this._set = set;
    }

    errorMessage(): string | undefined {
        return undefined;
    }

    set(t: T): void {
        this.value = t;
        this._set(t);
    }

    setData(appData: AppData): void {
    }

    isIt(t : T) : boolean{
        return this.value === t;
    }
}

export class FieldDivider extends SimpleFieldAdaptor<void>{
    constructor(name: string) {
        super(name, ()=>{}, undefined , true, undefined);
    }
}

export class BooleanFieldAdaptor extends SimpleFieldAdaptor<boolean>{

}

export class StringFieldAdaptor extends SimpleFieldAdaptor<string>{

}

export class TextFieldAdaptor extends StringFieldAdaptor{
    rows : number;

    constructor(name: string, set: (val: string) => void, value: string | undefined=undefined, rows: number=3,
                disabled: boolean=false, hint: string | undefined=undefined) {
        super(name, set, value, disabled, hint);
        this.rows = rows;
    }
}

export class NotEmptyStringFiled implements FormFieldAdaptor<string> {
    name : string;
    hint ?: string;
    _set :(val : string) => void;
    value ?: string;
    message: string;
    disabled: boolean;

    constructor(name: string, _set: (val : string)=> void, message :string = not_empty_field,
                _val: string|undefined = undefined, disabled : boolean = false,  hint : string|undefined = undefined) {
        this.name = name;
        this.hint = hint;
        this._set =_set;
        this.value = _val;
        this.message = message;
        this.disabled = disabled;
    }

    errorMessage(): string | undefined {
        if(this.disabled) return undefined;
        return this.value === undefined || this.value.length === 0 ? this.message : undefined;
    }

    set(t: string): void {
        this.value = t;
        this._set(t);
    }

    setData(appData: AppData): void {
    }

    isIt(t : string) : boolean{
        return this.value === t;
    }
}

export class UrlAdaptor extends NotEmptyStringFiled {

}

export class PictureUrlAdaptor extends UrlAdaptor{

}

export class VocabularyStringField extends NotEmptyStringFiled{
    vocabulary? : Vocabulary;

    constructor(name: string, set: (val: string) => void, val: string | undefined, disabled: boolean =false, hint: string | undefined = undefined) {
        super(name, set, not_empty_field, val, disabled, hint);
    }

    errorMessage(): string | undefined {
        if(this.disabled) return undefined;
        if( this.value === undefined || this.value.length === 0 ) return  this.message ;
        return this.vocabulary ? (this.vocabulary[this.value] ? undefined :key_not_found) : undefined;
    }

    setData(appData: AppData): void {
        this.vocabulary = appData.vocabulary;
    }
}


export class RegexFieldAdaptor extends NotEmptyStringFiled{
    regex : RegExp;
    notValid :string =wrong_format;
    constructor(name: string, _set: (val : string)=> void, message :string,
                _val: string|undefined = undefined, regex: RegExp, disabled : boolean = false,  hint : string|undefined = undefined) {
        super(name,_set,message,_val,disabled,hint);
        this.regex = regex;
    }
    errorMessage(): string | undefined {
        const base = super.errorMessage();
        if(base !== undefined) return base;
        if(this.disabled) return undefined;
        return this.regex.test(String(this.value).toLowerCase()) ? undefined : this.notValid;
    }
}

export function isEmail(str : string|undefined) : boolean {
    return !!str && emailRegExp.test(str)
}

export const emailRegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export class EmailFieldAdaptor extends RegexFieldAdaptor{
    constructor(name: string, _set: (val : string)=> void, message :string,
                _val: string|undefined = undefined, disabled : boolean = false,  hint : string|undefined = undefined) {
        super(name,_set,message,_val,
            emailRegExp
            ,disabled,hint);
        this.notValid = not_valid_email;
    }
}

export class ValidEmailFieldAdaptor extends EmailFieldAdaptor{
    bounced : boolean;
    intent ?: Intent;
    mark ?: string;
    icon ?: IconName;

    constructor(name: string, set: (val : string)=> void, message :string,
                val: string|undefined = undefined, bounced : boolean, disabled : boolean = false,  hint : string|undefined = undefined) {
        super(name, set, message, val, disabled, hint);
        this.bounced = bounced;

        this.icon = 'info-sign';
        this.intent = Intent.DANGER;
        this.mark = email_bounced;
    }
}

export class MarkedFieldAdaptor extends StringFieldAdaptor{
    intent : Intent;
    mark : string;
    icon : IconName;

    constructor(name: string, set: (val: string) => void, value: string|undefined=undefined, mark: string, intent: Intent = Intent.DANGER, icon: IconName = 'info-sign',  disabled: boolean = false, hint: string|undefined = undefined) {
        super(name, set, value, disabled, hint);
        this.intent = intent;
        this.mark = mark;
        this.icon = icon;
    }
}



export class PhoneFieldAdaptor extends SimpleFieldAdaptor<string>{
    notValid : string

    constructor(name: string, _set: (val : string)=> void, message :string,
                _val: string|undefined = undefined, disabled : boolean = false,  hint : string|undefined = undefined) {
        super(name,_set,toScreenFormat(_val)
            ,disabled,hint);
        this.notValid = not_valid_phone;
    }

    errorMessage(): string | undefined {
        if(this.disabled) return undefined;

        if (this.value === undefined || this.value.length === 0) {
            return not_empty_field;
        }

        return isValid(this.value) ? undefined : not_valid_phone;
    }

    set(t: string) {
        this.value = t;
        this._set(toStorageFormat(t));
    }
}


// export class PhoneFieldAdaptor extends RegexFieldAdaptor{
//     constructor(name: string, _set: (val : string)=> void, message :string,
//                 _val: string|undefined = undefined, disabled : boolean = false,  hint : string|undefined = undefined) {
//         super(name,_set,message,_val,
//             new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/)
//             ,disabled,hint);
//         this.notValid = not_valid_phone;
//     }
// }

export class UniqStringField extends NotEmptyStringFiled{
    _filterValues: (appData : AppData)=>string[];
    usedValues: string[];

    constructor(name: string, set: (val: string) => void, message: string, val: string | undefined,
                filterOptions: (appData: AppData) => string[], disabled : boolean = false, hint: string | undefined = undefined) {
        super(name, set, message, val, disabled, hint);
        this._filterValues = filterOptions;
        this.usedValues = [];
    }

    setData(appData: AppData): void {
        this.usedValues = this._filterValues(appData);
    }

    errorMessage(): string | undefined {
        if(this.disabled) return undefined;
        const res : string | undefined = super.errorMessage();
        if(res !== undefined) return res;
        return this.usedValues.indexOf(this.value!) >=0 ? uniq_value_required : undefined;
    }
}

export class  NotNegativeIntegerField implements FormFieldAdaptor<number> {
    name : string;
    hint ?: string;
    _set :(val : number) => void;
    value : number;
    disabled: boolean;
    readonly message: string = number_format_required;

    constructor(name: string, set: (val: number) => void, val: number=0, disabled : boolean = false,
                hint : string|undefined = undefined ) {
        this.name = name;
        this.hint = hint;
        this._set = set;
        this.value = val;
        this.disabled = disabled;
    }

    errorMessage(): string | undefined {
        if(this.disabled) return undefined;
        return this.value >=0 && Number.isInteger(this.value) ? undefined : this.message;
    }

    set(t: number): void {
        this.value = t;
        this._set(t);
    }

    setData(appData: AppData): void {
    }
}
export function getOptions(obj : any) {
    return  Object.keys(obj).map(key => mapTag(obj[key]));
}

export const ALIAS = 'alias';

export interface OptionsDescriptor {
    [key :string] :OptionDescriptor;
}

export interface OptionDescriptor {
    alias : string;
    isCustom : boolean;
    views :KV[];
}


export class SimpleOptionsFieldAdaptor implements OptionsFieldAdaptor<string>{
    name: string;
    _set :(val : string) => void;
    value ?: string;
    disabled: boolean;
    hint ?: string;
    options: string[];
    descriptors ?: OptionsDescriptor;

    constructor(name: string,  set: (val : string) => void, value: string|undefined=undefined, options: string[],
                disabled: boolean=false, hint: string|undefined = undefined) {
        this.name = name;
        this.value = value;
        this.disabled = disabled;
        this.hint = hint;
        this.options = options;
        this._set = set;
    }

    errorMessage(): string | undefined {
        return  undefined;
    }

    set=(t: string): void=> {
        const val = t === nothing_selected ? undefined : t;
        this.value = val;
        this._set(val === undefined ? '': val);
    };

    setData(appData: AppData): void {
    }

    isIt(t : string) : boolean{
        return this.value === t;
    }
}

export class EnumOptionsAdaptor extends SimpleOptionsFieldAdaptor{
    constructor(name: string,  set: (val : string) => void, value: string|undefined=undefined, options : any, disabled: boolean=false, hint: string|undefined = undefined) {
        super(name,set,value,getOptions(options),false,hint);
    }
}

export class StringOptionsFieldAdaptor extends SimpleOptionsFieldAdaptor{
    _filterOptions: (appData : AppData)=>string[];
    valueRequired ?: boolean;
    addNothing : boolean;
    getDescriptors :(appData: AppData) =>  OptionsDescriptor| undefined = ()=>undefined;

    constructor(name: string, set: (val : string) => void, value: string|undefined,
                filterOptions: (appData: AppData) => string[],addNothing :boolean=false, valueRequired : boolean|undefined= undefined,
                hint: string|undefined=undefined,getDescriptors :(appData: AppData) =>  OptionsDescriptor| undefined = ()=>undefined) {
        super(name,set,value,[],false,hint);
        this._filterOptions = filterOptions;
        this.valueRequired = valueRequired;
        this.addNothing = addNothing;
        this.getDescriptors = getDescriptors;
    }

    errorMessage(): string | undefined {
        return this.valueRequired && (this.value === undefined || this.value.length ===0 )? not_empty_field : undefined;
    }

    setData(appData: AppData): void {
        this.options = this._filterOptions(appData);
        if(this.getDescriptors) this.descriptors = this.getDescriptors(appData);
        if(this.addNothing) this.options.unshift(nothing_selected);
    }
}

export class StringOptionsArrayAdaptor extends  StringOptionsFieldAdaptor{
    values : string[];

    constructor(name: string, set: (val : string) => void, value: string|undefined,
                filterOptions: (appData: AppData) => string[], values: string[],valueRequired : boolean|undefined= undefined,
                hint: string | undefined=undefined, getDescriptors :(appData: AppData) =>  OptionsDescriptor| undefined = ()=>undefined) {
        super(name, set, value, filterOptions, valueRequired, true, hint, getDescriptors);
        this.values = values;
    }

    set=(t: string): void =>{
        const val = t === nothing_selected ? '' : t;
        this.values.push( val);
        this._set(val);
    };

    setData(appData: AppData): void {
        this.options = this._filterOptions(appData);
        if(this.getDescriptors) this.descriptors = this.getDescriptors(appData);
        this.options.unshift(nothing_selected);
    }
}

export interface ObjectAdaptor {
    object : EmbeddedObject;
    icon : IconName | MaybeElement;
    expanded : boolean;

    isNegative() : boolean;
    getText() :string;
}

export class EncodedObjectAdaptor extends StringFieldAdaptor implements ObjectAdaptor{
    autoCommit : boolean = true;
    object : EncodedObject;
    icon : IconName | MaybeElement;
    expanded : boolean;

    constructor(name: string, set: (val: string) => void, value: string | undefined,
                convert :(val : string|undefined,set:(val: string)=>void)=>EncodedObject, icon : IconName | MaybeElement,
                expanded : boolean = false,
                disabled: boolean = false, hint: string | undefined = undefined) {
        super(name, set, value, disabled, hint);
        /*
          Object is created from the string
          What to do on save is injected
          Really its setter of master object
         */
        this.object = convert(value,this._set);
        this.icon = icon;
        this.expanded = expanded;
    }

    getText=():string=>{
        return this.object.toString();
    };

    isNegative=() : boolean=>{
        return this.object.isNegative()
    };

    setData(appData: AppData): void {
        this.object.setData(appData);
    }
}

export class EmbeddedObjectAdaptor extends SimpleFieldAdaptor<EmbeddedObject> implements ObjectAdaptor{
    object : EmbeddedObject;
    icon : IconName | MaybeElement;
    expanded : boolean;
    autoCommit : boolean = true;
    constructor(name: string, set: (val: EmbeddedObject) => void, value: any,
                convert :(val : any,set:(val: EmbeddedObject)=>void)=>EmbeddedObject, icon : IconName | MaybeElement,
                expanded : boolean = false,
                disabled: boolean = false, hint: string | undefined = undefined) {
        super(name, set, value, disabled, hint);
        this.object = convert(value,this._set);
        this.icon = icon;
        this.expanded = expanded;
    }

    getText=():string=>{
        return this.object.toString();
    };

    isNegative=() : boolean=>{
        return this.object.isNegative()
    };

    setData(appData: AppData): void {
        this.object.setData(appData);
    }
}
export class FileFieldAdaptor extends SimpleOptionsFieldAdaptor{
    emptyMessage ?: string;
    getLinksRequest : DataType;
    pathVar ?: string;
    links ?: Vocabulary;

    constructor(name: string, set: (val: string) => void, value: string | undefined, emptyMessage : string | undefined = undefined,
                type : DataType, pathVar : string|undefined = undefined,
                options: string[]=[], disabled: boolean=false, hint: string | undefined = undefined) {
        super(name, set, value, options, disabled, hint);
        this.getLinksRequest =type;
        this.pathVar = pathVar;
        this.emptyMessage = emptyMessage;
    }

    setData(appData: AppData): void {
        let vocabulary : Vocabulary;

        switch (this.getLinksRequest) {
            case DataType.GetStudentUploadVideoLink:
                vocabulary = appData.homeworkVideoLinks;
                break;
            case DataType.GetStudentUploadFilesLink:
                vocabulary = appData.homeworkFileLinks;
                break;
            case DataType.GetUploadVideoLink:
                vocabulary = appData.videoLinks;
                break;
            case DataType.GetUploadFileLink:
                vocabulary = appData.fileLinks;
                break;
            default:
                vocabulary = {};
        }
        this.links = vocabulary;
        this.options = Object.keys(vocabulary);
        this.options.unshift(nothing_selected);
    }

    getLink =():string|undefined=>{
        return this.links && this.value ? this.links[this.value] : undefined;
    }
}
export class VideoFieldAdaptor extends FileFieldAdaptor {

}

export class TagsAdaptor extends SimpleFieldAdaptor<StringArrayElement>{
    presentTags : string[];
    onAddTag : (tag: string)=> void;
    onRemoveTag : (tag: string)=> void;
    allTags : string [];
    filterOptions: (appData : AppData)=>string[];
    useVocabulary : boolean;

    constructor(name: string,
                presentTags: string[], onAddTag: (tag: string) => void, onRemoveTag: (tag: string) => void
        , filterOptions: (appData : AppData)=>string[],useVocabulary :boolean = true,
                disabled: boolean=false, hint: string | undefined = undefined) {
        super(name, (val : StringArrayElement)=>{}, undefined, disabled, hint);
        this.presentTags = presentTags;
        this.onAddTag = onAddTag;
        this.onRemoveTag = onRemoveTag;
        this.filterOptions= filterOptions;
        this.allTags = [];
        this.useVocabulary = useVocabulary;
    }

    set(t: StringArrayElement): void {
        if(t.present){
            this.onRemoveTag(t.value);
            return;
        }
        this.onAddTag(t.value);
    }

    getFilteredTags = (): string []=>{
        if(this.disabled){
            return this.presentTags;
        }
        return this.presentTags.filter(tag=>this.allTags.indexOf(tag)>=0);
    };

    getNotListedTags = () : string [] =>{
        if (this.disabled){
            return [];
        }
        return this.allTags.filter(tag => this.presentTags.indexOf(tag)<0);
    };

    getTagsKV = ():KV[]=>{
        return this.getFilteredTags().map(tag => new SaveKV(
            mapTag(tag),true,(val :any)=>this.set({value :tag, present :true}))
        );
    };

    getOtherTagsKV = ():KV[]=>{
        return this.getNotListedTags().map(tag => new SaveKV(
            mapTag(tag),false,(val :any)=>this.set({value :tag, present :false}))
        );
    };

    setData(appData: AppData) {
        this.allTags = this.filterOptions(appData);
    }
}

export class RemoveTagAdaptor extends TagsAdaptor{

    constructor(name: string,
                presentTags: string[], allTags: string[], onAddTag: (tag: string) => void, onRemoveTag: (tag: string) => void
        , filterOptions: (appData : AppData)=>string[],useVocabulary :boolean = true,
                disabled: boolean=false, hint: string | undefined = undefined) {
        super(name, presentTags, onAddTag, onRemoveTag, filterOptions, useVocabulary, disabled, hint);
        this.allTags = allTags;
    }

    setData(appData: AppData) {

    }
}

export class ExpireTagsAdaptor extends TagsAdaptor{
    expire ?: IExpire

    constructor(
        name: string,
        presentTags: string[], onAddTag: (tag: string) => void, onRemoveTag: (tag: string) => void
        , filterOptions: (appData : AppData)=>string[], useVocabulary :boolean = true,  expire ?: IExpire,
        disabled: boolean=false, hint: string | undefined = undefined
    ) {
        super(name, presentTags, onAddTag, onRemoveTag, filterOptions, useVocabulary, disabled, hint);
        this.expire = expire;
    }
}

export class LimitedTagsAdaptor extends TagsAdaptor{
    restrictedTags : string [];
    intent: Intent;

    constructor(
        name: string,
        presentTags: string[], onAddTag: (tag: string) => void, onRemoveTag: (tag: string) => void
        , filterOptions: (appData : AppData)=>string[], restrictedTags :string [], useVocabulary :boolean = true, intent: Intent = Intent.SUCCESS,
        disabled: boolean=false, hint: string | undefined = undefined
    ) {
        super(name, presentTags, onAddTag, onRemoveTag, filterOptions, useVocabulary, disabled, hint);
        this.restrictedTags = restrictedTags;
        this.intent = intent;
    }

    setData(appData: AppData) {
        this.allTags = this.filterOptions(appData).filter(tag => this.restrictedTags.indexOf(tag) < 0);
    }
}

export class ObjectArrayAdaptor extends SimpleFieldAdaptor<EmbeddedObject[]>{
    icon : IconName | MaybeElement;
    autoCommit : boolean = true;
    onInsert : (index : number) => void;
    onDelete : (index : number) =>void;

    constructor(name: string, set: (val: EmbeddedObject[]) => void, value: EmbeddedObject[]|undefined=undefined, icon : IconName | MaybeElement,
                onInsert : (index : number) => void,onDelete : (index : number) =>void,
                disabled: boolean = false, hint: string|undefined = undefined) {
        super(name,set,value,disabled,hint);
        this.icon = icon;
        this.onInsert = onInsert;
        this.onDelete = onDelete;
    }

    setData(appData: AppData): void {
            for(let i=0; i<this.value!.length; i++) this.value![i].setData(appData);
    }

    insert(index : number){
        this.onInsert(index);
    }
}

export type FieldAdaptor = FormFieldAdaptor<string> | FormFieldAdaptor<number>| FormFieldAdaptor<void> | FormFieldAdaptor<EmbeddedObject> |  FormFieldAdaptor<EmbeddedObject[]>
    | FormFieldAdaptor<boolean> | FormFieldAdaptor<EncodedObject> | FormFieldAdaptor<StringArrayElement> ;

