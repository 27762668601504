import {MODE_DOWNLOADS, MODE_JOIN, SCHOOL, VAR_DELIMITER} from "../service/Literals";
import {ADMIN} from "../actions/settings";

export interface IPathVariables {
    mode ?: string;
    joinClassroom ?: number;
}

export function isAdmin(mode: string | undefined) {
    return mode === ADMIN;
}

export function admin(args ?: PathVariables): boolean {
    if (!args) return false
    return isAdmin(args.mode);
}

export class PathVariables implements IPathVariables{
    mode ?: string;
    joinClassroom ?: number;
    downloads ?:boolean;
    targetSchool ?: string;

    constructor(mode: string|undefined, joinClassroom: number|undefined = undefined, downloads : boolean=false, school: string|undefined = undefined) {
        this.mode = mode;
        this.joinClassroom = joinClassroom;
        this.downloads =downloads;
        this.targetSchool = school
    }

    hasTargetSchool = () : boolean=> {
        return !! this.targetSchool
    }
}

export function parseVariables(val : string|undefined) : PathVariables {
    if (val === undefined) return new PathVariables(undefined);
    const data : string[] = val.split(VAR_DELIMITER);
    let joinClassroom : number| undefined = undefined;
    if(data[0] === MODE_JOIN && data.length > 1){
        joinClassroom = +data[1];
        return new PathVariables(undefined,joinClassroom);
    }
    if(data[0] === MODE_DOWNLOADS){
        return new PathVariables(undefined,undefined,true);
    }
    if(data[0] === SCHOOL){
        return new PathVariables(data[0])
    }

    let targetSchool = data.length >= 3 && data[1] === SCHOOL ? data[2] : undefined;

    return new PathVariables(data[0],undefined,undefined,targetSchool);
}
