import React, {FC} from 'react';
import {ExpireTagsAdaptor, LimitedTagsAdaptor, TagsAdaptor} from "../../adaptors/FieldAdaptor";
import TagRequestView from "./TagRequestView";
import {Card, Elevation} from "@blueprintjs/core";
//done
import {available_alternatives} from "../../text/Literals";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Vocabulary} from "../../model/AppData";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {IExpire} from "../../api/School";

interface TagsEditFieldProps {
    adaptor : TagsAdaptor,
    vocabulary : Vocabulary
}

const TagsEditField : FC<TagsEditFieldProps> = props => {
    let intent : Intent | undefined;
    if (props.adaptor instanceof LimitedTagsAdaptor){
        intent = props.adaptor.intent;
    }
    let expire : IExpire | undefined;
    if (props.adaptor instanceof ExpireTagsAdaptor){
        expire = props.adaptor.expire;
    }
    return (
        <div>
            <Card interactive={false} elevation={Elevation.THREE}>
                <TagRequestView
                    expire={expire}
                    useVocabulary={props.adaptor.useVocabulary}
                    label={props.vocabulary[props.adaptor.name] }
                    adaptors={props.adaptor.getTagsKV()}/>
            </Card>
            { props.adaptor.disabled ? null :
            <Card interactive={false} elevation={Elevation.THREE}>
                <TagRequestView
                    intent={intent}
                    useVocabulary={props.adaptor.useVocabulary}
                    label={props.vocabulary[available_alternatives] }
                    adaptors={props.adaptor.getOtherTagsKV()}/>
            </Card>}
        </div>);
};
const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary
});
export default connect(mapStateToProps)(TagsEditField)