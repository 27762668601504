import { ThunkAction } from "redux-thunk";
import {AppState} from "../store/configureStore";
import {Request} from "../model/Request";
import {ExceptionType, onException, noException} from "../actions/error";
import {Action} from "redux";
import {onRequestSent,becomeIdle} from "../actions/process";
import {send} from "../service/Connection";
import {receiveRawData,DataType} from "../actions/data";
import {refresh} from './refresh';
import {loadUser, User} from "../model/User";
import {receiveAuth} from "../actions/user";
import {ADMIN} from "../actions/settings";
import firebaseLogin from "../thunks/firebaseLogin";
import logout from "./logout";


export const simpleRequest = (
    request: Request,
    onSuccess : (val : string|undefined)=>void,
    onError ?:(e : any)=>void
): ThunkAction<void, AppState, null, Action> => async (dispatch ) => {

    function handleError(e :any) {
        //  console.log(e);
        try{
            dispatch(onException(e.status));
        }catch (e2) {
            console.log(e2);
        }
        dispatch(becomeIdle());
    }
    try {
        dispatch(onRequestSent());
        dispatch(onException(ExceptionType.Processing));
        const response = await send(request);
        dispatch(becomeIdle());
        dispatch(noException());
        onSuccess(response.message);
    } catch (e) {
        handleError(e);
        onError && onError(e);
    }
};


export const sendRequest = (
     request: Request,
     type : DataType,
     user : User| undefined = undefined,
     onSuccess ?: (val : string|undefined)=>void,
     errorHandler ? :(e : any)=>void
)
: ThunkAction<void, AppState, null, Action> => async (dispatch ,getState) => {

     function onError(e :any) {
        //  console.log(e);
          try{
              dispatch(onException(e.status));
          }catch (e2) {
              console.log(e2);
          }
          dispatch(becomeIdle());
     }

     function onRefreshError(e : any) {
        onError(e);
         if(e.status === ExceptionType.Unauthorized || e.status === ExceptionType.DisabledUser){
             dispatch(logout());
             return;
         }
     }

     try {
               dispatch(onRequestSent());
               dispatch(onException(ExceptionType.Processing));
               // const msg = request.getMessage();
               const response = await send(request);
               if(response.message !== undefined && response.message.length > 0) {
                   console.log("got data: "+type);
                   dispatch(receiveRawData(type, response.message));
               }
               if(onSuccess !== undefined){
                   onSuccess(response.message);
               }
               dispatch(becomeIdle());
               dispatch(noException());
               return;
          } catch (e) {
               // console.log(e);
               if(e === undefined){
                    dispatch(onException(0));
                    dispatch(becomeIdle());
                    return;
               }
               if(errorHandler){
                   errorHandler(e)
               }

               if(e.status === ExceptionType.Unauthorized){
                    const usr = user ?  user : getState().user;
                    let u: User = usr  ? usr : loadUser();
                    if(u.refreshToken.length ===0){
                         onError(ExceptionType.Unauthorized);
                         return;
                    }
                    refresh(u,u.isAdmin() ? ADMIN : undefined).then(async auth=>{
                         dispatch(receiveAuth(auth));
                         dispatch(firebaseLogin(auth));
                         try {
                              request.authorization = auth.token;
                              const response = await send(request);
                              dispatch(receiveRawData(type, response.message));
                              dispatch(becomeIdle());
                              dispatch(noException());
                         }catch (e) {
                             onRefreshError(e);
                         }

                    } ).catch(e=>{
                         onError(e);
                    });
                    return;
               }
               onError(e);
          }
};