import React, {FC,memo} from 'react';
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {Vocabulary} from "../../model/AppData";
import {Pre} from "@blueprintjs/core";
import {total_students_course} from "../../text/Literals";

interface StudentsCounterProps {
    counter : number;
    vocabulary : Vocabulary;
}

const StudentsCounter : FC<StudentsCounterProps>= props => {
    return   <Pre className='form-item-row'>
                {props.vocabulary[total_students_course] + props.counter}
            </Pre>
};

const mapStateToProps = (state :AppState)=>{
    const counter: number = state.data.students[state.instruments.entity.idAsString()]
        ? state.data.students[state.instruments.entity.idAsString()].length : 0;
    return {
        counter,
        vocabulary : state.data.vocabulary
    }
};

export default connect(mapStateToProps)(memo(StudentsCounter));