import React,{Component} from "react";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {firebaseSend} from "../../thunks/firebase";
import {
    FirebaseMessageType,
    FirebaseSignedHomework,
    FirebaseStudentsHomework
} from "../../model/common/FirebaseMessage";
import {CoursesPositions, CourseViewerPosition} from "../../actions/settings";
import HomeworkView from "./HomeworkView";
import {Course} from "../../model/courses/Course";
import {Alignment, Navbar, Tab, Tabs} from "@blueprintjs/core";
import {
    all_students_homework,
    current_course_homework, current_lesson_homework,
} from "../../text/Literals";
import {Vocabulary} from "../../model/AppData";
import {User} from "../../model/User";
import {VAR_DELIMITER} from "../../service/Literals";

interface  HomeworkReviewerProps{
    firebaseStudentsHomework : FirebaseStudentsHomework
    course ?: Course;
    coursePositions : CoursesPositions;
    useFilter ?: boolean;
    vocabulary : Vocabulary;
    user : User
}
type MessagePage = "all_students_homework" | "current_course_homework" | "current_lesson_homework"

interface HomeworkReviewerState {
    page : MessagePage
}

class HomeworkReviewer extends Component<HomeworkReviewerProps,HomeworkReviewerState>{

    constructor(props: Readonly<HomeworkReviewerProps>) {
        super(props);
        this.state = {page :current_course_homework }
    }

    handleChangeTab=(page: MessagePage)=>{
        this.setState({page})
    };

    render(){

        return <div className='sidebar-content tiles-container-vertical'>
            <Navbar >
                <Navbar.Group align={Alignment.RIGHT}>
                    <Tabs
                        animate={true}
                        id="navbar"
                        large={false}
                        onChange={this.handleChangeTab}
                        selectedTabId={this.state.page}
                    >
                        <Tab id={all_students_homework} title={this.props.vocabulary[all_students_homework]}/>
                        <Tab id={current_course_homework} title={this.props.vocabulary[current_course_homework]}/>
                        <Tab id={current_lesson_homework} title={this.props.vocabulary[current_lesson_homework]}/>
                    </Tabs>
                </Navbar.Group>
            </Navbar>
            {this.getHomeworkMessages()}
        </div>;
    }

    private getHomeworkMessages() {
        let arr : FirebaseSignedHomework[]=[];
        if(!this.props.course || this.state.page === all_students_homework) {
            return  <div className='tiles-item-full-row'>
                {Object.keys(this.props.firebaseStudentsHomework)
                    .map(key=>
                            <div key={key}>{
                                this.displayMessageArray(this.props.firebaseStudentsHomework[key],
                                new Course(this.props.user.getStudentsCourseById(key)))
                            }
                            </div>
                    )
                }
            </div>;
        } else{
            arr = this.props.firebaseStudentsHomework[this.props.course.id];
            return <div className='tiles-item-full-row'>
                {this.displayMessageArray(arr,this.props.course!)}
            </div>;
        }

    }
    private displayMessageArray(arr: FirebaseSignedHomework[], course : Course) {
        const pos = this.props.coursePositions[course.id];
        return Array.isArray(arr) ?
            arr.map((hw,index) => <div key={index}>
                    {this.filterLessons(Object.keys(hw.homework), pos).map(
                        key => <HomeworkView
                            key={key}
                            course={course}
                            student={hw.student}
                            lesson={key}
                            file={hw.homework[key]}
                            answer={this.getAnswer(course.id,hw.student,key)}
                        />
                    )}
                </div>
            )
            :null;
    }

    private filterLessons= (strings: string[], pos: CourseViewerPosition|undefined=undefined) : string[]=> {
        const withOutAnswers = strings.filter(s=>s.indexOf(FirebaseMessageType.HomeworkReview)<0);
        if (pos === undefined || this.state.page !== current_lesson_homework) return withOutAnswers;
        return withOutAnswers.filter(s=>s === pos.uuid);
    };

    private getAnswer(id: string, student: string, key: string) : string|undefined {
        const courseHomeWorks = this.props.firebaseStudentsHomework[id];
        if(!courseHomeWorks) return undefined;
        const students = courseHomeWorks.findIndex(hw=>hw.student === student);

        if(students < 0) return undefined;

        return courseHomeWorks[students].homework[FirebaseMessageType.HomeworkReview+VAR_DELIMITER+key];
    }
}

const mapStateToProps = (state: AppState) => ({
    firebaseStudentsHomework : state.data.firebaseStudentsHomework,
    coursePositions : state.settings.coursesPositions,
    vocabulary : state.data.vocabulary,
    user : state.user
});
export default connect(
    mapStateToProps,{firebaseSend}
)(HomeworkReviewer);
