import React,{Component} from 'react';

interface AudioPlayerProps {
    url : string;
}

interface AudioPlayerState {
    isPlaying : boolean;
}

export class AudioPlayer extends Component<AudioPlayerProps,AudioPlayerState>{
    audio : HTMLAudioElement;

    constructor(props: Readonly<AudioPlayerProps>) {
        super(props);
        this.state = {isPlaying : false};
        this.audio = new Audio(props.url);
    }

    render(){
        return    (
            <audio
                className='bp3-dark base-margin block'
            controls
            src={this.props.url}>
            Your browser does not support the
            <code>audio</code> element.
        </audio>)
    }
    // play=()=>{
    //     this.audio.play();
    // };
    //
    // pause =()=>{
    //   this.audio.pause();
    // }
    //
    // stop =()=>{
    //     this.audio.s
    // }
}