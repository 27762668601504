import React, {PureComponent} from "react";
import InstrumentContainer from "../InstrumentContainer";
import {InstrumentType} from "../../actions/instruments";
import {Txt} from "../tools/Text";
import {connect} from "react-redux";
import {sendRequest} from "../../thunks/sendRequest";
import {FreeDlcDescription} from "../../api/Dlc";
import {AppState} from "../../store/configureStore";
import {FreeCourseInfo} from "../courses/CourseInfo";
import {free_courses} from "../../text/Literals";
import {CourseData} from "../../model/courses/Course";


interface FreeCourseBrowserProps {
    sendRequest : any;
    freeCoursesDescriptions : FreeDlcDescription [];
    ownCourses ?: CourseData[];
}

interface FreeCourseBrowserState {

}

class FreeCourseBrowser extends PureComponent<FreeCourseBrowserProps,FreeCourseBrowserState>{
    render(){
        const ownCourses : string[] = this.props.ownCourses ? this.props.ownCourses.map(course =>course.id) : [];
        return <InstrumentContainer
                title={new Txt().dict(free_courses)}
                instrument={InstrumentType.FreeCourses}>
            <div className='base-width tiles-container-start'>
                {this.props.freeCoursesDescriptions
                    .filter(description => ownCourses.indexOf(description.id) <0)
                    .map(description=><FreeCourseInfo key={description.id} data ={description} />)}
            </div>
        </InstrumentContainer>
    }
}

const mapStateToProps = (state : AppState)=>({
    freeCoursesDescriptions : state.data.freeCoursesDescriptions,
    ownCourses : state.user.studentsCourses
});

export default connect(mapStateToProps,{sendRequest})(FreeCourseBrowser)