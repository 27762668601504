import React, {PureComponent} from 'react';
import './styles/App.scss';
import {connect} from 'react-redux';
import {AppState} from "./store/configureStore";
import {Callout, Card, Intent, Position} from "@blueprintjs/core";
import LoginDialog from './components/LoginDialog';
import RoleBasedContainer from './components/RoleBasedContainer';
import {Role, User} from "./model/User";
import InstrumentButton from "./components/InstrumentButton";
import {Elevation} from "@blueprintjs/core/lib/esm/common/elevation";
import {closeInstrument, Instruments, InstrumentType} from "./actions/instruments";
import InstrumentContainer from './components/InstrumentContainer';
import PlayerViewerInstrument from "./components/instruments/PlayerViewerInstrument";
import refresh from './thunks/refresh';
import useInstrument from "./thunks/useInstrument";
import DlcEditorInstrument from "./components/instruments/DlcEditorInstrument";
import SchoolInstrument from "./components/instruments/SchoolInstrument";
import Toolbar from "./components/tools/ToolBar";
import {match} from "react-router";
import TeacherCabinetInstrument from './components/instruments/TeacherCabinetInstrument';
import {admin, parseVariables, PathVariables} from "./model/PathVariables";
import {Entity} from "./model/Entity";
import ClassroomInstrument from './components/instruments/ClassroomInstrument';
import ToolContainer from "./components/tools/ToolContainer";
import FactPlayer from "./components/players/FactPlayer";
import ButtonOrganizer, {ButtonSizes} from "./components/ButtonOrganizer";
import {Course} from "./model/courses/Course";
import {getBackGroundStyle} from "./actions/settings";
import DownloadsControl from "./components/tools/DownloadsControl";
import {
    course_creation,
    create_course,
    dlc_title,
    get_free_courses,
    hardware_info,
    messages_label,
    sensors_label,
    start_analytics_processor,
} from "./text/Literals";
import {getVocabulary} from "./thunks/changeLanguage";
import MessageBox from "./components/tools/MessageBox";
import {DataType, markMessagesRed} from "./actions/data";
import CourseTemplateEditor from "./components/courses/CourseTemplateEditor";
import CourseEditorInstrument from "./components/instruments/CourseEditorInstrument";
import CourseViewerInstrument from "./components/instruments/CourseViewerInstrument";
import {Text, Txt} from "./components/tools/Text";
import CourseInfo from "./components/courses/CourseInfo";
import {CourseRejectReview, CourserRequestReview} from "./components/courses/CourseReview";
import ReviewPanel from "./components/tools/ReviewPanel";
import UnderReviewPanel from "./components/tools/UnderReviewPanel";
import FreeCourseBrowser from "./components/instruments/FreeCourseBrowser";
import CourseDeleteDialog from "./components/courses/CourseDeleteDialog";
import DefaultVitrineEditor from "./components/editors/DefaultVitrineEditor";
import RequestButton from "./components/RequestButton";
import {Method, Request} from "./model/Request";
import SchoolApplication from "./components/instruments/SchoolEditor";
import {SchoolUser} from "./api/School";
import {DROZDOV, MY, REGISTER, SCHOOL} from "./service/Literals";
import SchoolRegisterInstrument from "./components/instruments/SchoolRegisterInstrument";
import MySchoolInstrument from "./components/instruments/MySchoolInstrument";
import DrozdovTrialInstrument from "./components/instruments/DrozdovTrialInstrument";
import HardwareInstrument from "./components/instruments/HardwareInstrument";
import SensorInstrument from "./components/instruments/SensorInstrument";

interface AppProps {
    user : User,
    schoolUser ?: SchoolUser,
    refresh : any,
    useInstrument : any,
    getVocabulary : any,
    markMessagesRed : any,
    closeInstrument : any,
    match : match<Identifiable>
}

interface Identifiable {mode: string; instrument : string; entity : string }

class App extends PureComponent <AppProps> {
    args ?: PathVariables;

    componentDidMount(): void {
        let instruments : Instruments|undefined = App.getDefaultInstruments(this.props.user);
        this.args = parseVariables(this.props.match.params.mode);
        console.log(this.props.match.params)
        if(admin(this.args)){
            document.body.classList.add( 'body-dark');
        } else{
            document.body.classList.remove( 'body-dark');
        }
        this.props.getVocabulary();
        if(this.args.downloads){
            if(!this.props.user.isLoggedIn){
                this.props.useInstrument(InstrumentType.Downloads);
                return;
            }
            instruments = new Instruments(InstrumentType.Downloads);
        }

        if(this.args.hasTargetSchool()){
            instruments = new Instruments(InstrumentType.SchoolUser,{"id": this.args.targetSchool, "label" :this.args.targetSchool!})
        } else if (this.props.match.params.instrument === SCHOOL) {
            let targetSchool = this.props.match.params.entity;

            if (admin(this.args)) {
                instruments = new Instruments(InstrumentType.SchoolUser, {"id": targetSchool, "label": targetSchool!})
            }
        }

        if (this.props.match.params.mode === SCHOOL){
            if (this.props.match.params.instrument === REGISTER ){
                instruments = new Instruments(InstrumentType.SchoolRegister)
            } else
            if (this.props.match.params.instrument === MY ){
                instruments = new Instruments(InstrumentType.MySchool)
            } else
            if (this.props.match.params.instrument === DROZDOV ){
                instruments = new Instruments(InstrumentType.TrialShd)
            }
        }

        this.props.refresh(this.props.user,instruments,this.args.mode,this.args.joinClassroom);
    }

     static getDefaultInstruments(user: User) : Instruments|undefined {
        if(user.authorities.indexOf(Role.ROLE_SCHOOL_MANAGER) >= 0 && user.authorities.length ===1)
            return new Instruments(InstrumentType.SchoolViewer);
        return undefined;
    }

    getBackGroundClass =()=>{
        return getBackGroundStyle(this.args === undefined ? undefined :this.args.mode);
    };

    render() {
      //  console.trace("__render main page___");
        return (
              <div className={"App " + this.getBackGroundClass()} >
                  {/*                     Instruments for thous who have ADMIN privilege                  */}
                 <div className='main-area'>
                    <RoleBasedContainer required={Role.ROLE_ADMIN}>
                          <Card interactive={true} elevation={Elevation.TWO} className='base-margin base-width'>
                              <ButtonOrganizer title={new Txt().dict("admin_tools_label")} minimal={true} intent={Intent.SUCCESS } buttonSize={ButtonSizes.XLarge}>
                                <InstrumentButton intent={Intent.SUCCESS}
                                              icon='blocked-person'
                                              type={InstrumentType.PlayerViewer}
                                              description={new Txt().dict("player_info_description")}>
                                    <Text text={new Txt().dict("player_info")}/>
                                </InstrumentButton>
                                  <InstrumentButton intent={Intent.PRIMARY}
                                                    icon='desktop'
                                                    type={InstrumentType.Hardware}
                                                    description={new Txt().dict(hardware_info)}>
                                      <Text text={new Txt().dict(hardware_info)}/>
                                  </InstrumentButton>
                                  <InstrumentButton intent={Intent.PRIMARY}
                                                    icon='pulse'
                                                    type={InstrumentType.Sensors}
                                                    description={new Txt().dict(sensors_label)}>
                                      <Text text={new Txt().dict(sensors_label)}/>
                                  </InstrumentButton>
                                <InstrumentButton intent={Intent.WARNING}
                                            icon='shop'
                                            type={InstrumentType.DlcEditor}
                                            description={new Txt().dict("dlc_stock_list")}>
                                    <Text text={new Txt().dict(dlc_title)}/>
                                </InstrumentButton>
                                  <RequestButton
                                        type={DataType.Void}
                                        intent={Intent.PRIMARY}
                                        icon='grouped-bar-chart'
                                        request={new Request(undefined,"tools",Method.GET,this.props.user.token)}
                                        disabled={false}
                                        voidMessage={""}
                                  >
                                      <Text text={new Txt().dict(start_analytics_processor)}/>
                                  </RequestButton>
                              </ButtonOrganizer>
                          </Card>
                        <InstrumentContainer
                            instrument={InstrumentType.Hardware}
                            title={new Txt().dict(hardware_info)}
                            intent={Intent.PRIMARY}>
                            <HardwareInstrument/>
                        </InstrumentContainer>
                        <InstrumentContainer
                            dataToErase={DataType.ClearSensorAnalytics}
                            instrument={InstrumentType.Sensors}
                            title={new Txt().dict(sensors_label)}
                            intent={Intent.PRIMARY}>
                            <SensorInstrument/>
                        </InstrumentContainer>
                          <InstrumentContainer
                            instrument={InstrumentType.PlayerViewer}
                            title={new Txt().dict("player_info")}
                            intent={Intent.SUCCESS}>
                              <PlayerViewerInstrument/>
                          </InstrumentContainer>
                        <InstrumentContainer
                            instrument={InstrumentType.DlcEditor}
                            title={new Txt().dict("dlc_title")}>
                            <DlcEditorInstrument/>
                        </InstrumentContainer>
                        {/*<AnalyticsInstrument/>*/}
                    </RoleBasedContainer>

                  {/*                         Instruments for thous who have SCHOOL_MANAGER privilege                      */}

                      <RoleBasedContainer required={Role.ROLE_SCHOOL_MANAGER}>
                          <Card interactive={true} elevation={Elevation.TWO} className='base-margin base-width'>
                              <ButtonOrganizer title={new Txt().dict("school_administration")} minimal={true} intent={Intent.SUCCESS} buttonSize={ButtonSizes.XLarge}>
                                  <InstrumentButton intent={Intent.SUCCESS}
                                                    icon='office'
                                                    type={InstrumentType.SchoolViewer}
                                                    description={new Txt().dict("school_instrument")}>
                                      <Text text={new Txt().dict("schools_label")}/>
                                  </InstrumentButton>
                                  <InstrumentButton intent={Intent.WARNING}
                                                    icon='media'
                                                    type={InstrumentType.Vitrines}
                                                    description={new Txt().dict("vitrine_info")}>
                                      <Text text={new Txt().dict("vitrine_label")}/>
                                  </InstrumentButton>
                                  {
                                      this.props.schoolUser === undefined ? null :
                                          <InstrumentButton intent={Intent.PRIMARY}
                                                            icon='office'
                                                            entity={new Entity({"id" :this.props.schoolUser.school.username,"label" :this.props.schoolUser.school.username})}
                                                            type={InstrumentType.SchoolUser}
                                                            description={new Txt().txt(this.props.schoolUser.school.username)}>
                                              <Text text={new Txt().txt(this.props.schoolUser.school.username)}/>
                                          </InstrumentButton>
                                  }
                              </ButtonOrganizer>
                          </Card>
                          <InstrumentContainer
                              onTop={true}
                              fullscreen={true}
                              instrument={InstrumentType.SchoolViewer}
                              title={new Txt().dict("schools_label")}>
                              <SchoolInstrument/>
                          </InstrumentContainer>
                          <InstrumentContainer
                              onTop={true}
                              instrument={InstrumentType.Vitrines}
                              title={new Txt().dict("vitrine_label")}>
                              <DefaultVitrineEditor/>
                          </InstrumentContainer>
                          <InstrumentContainer
                              onTop={true}
                              instrument={InstrumentType.SchoolUser}
                              title={new Txt().dict("schools_label")}>
                              <SchoolApplication/>
                          </InstrumentContainer>
                      </RoleBasedContainer>

                      { this.props.user.schools === undefined || this.props.user.schools.length === 0 ? null:

                          /*                           Instruments for thous who is a teacher in school                            */

                          <RoleBasedContainer>
                              <Card interactive={true} elevation={Elevation.TWO} className='base-margin base-width'>
                                    <ButtonOrganizer title={new Txt().dict("schools_label")} minimal={true} intent={Intent.SUCCESS}>
                                          { this.props.user.schools.map((school,index)=>(
                                              <InstrumentButton
                                                    key = {index}
                                                    intent={Intent.SUCCESS}
                                                    icon='office'
                                                    type={InstrumentType.TeachersCabinet}
                                                    entity={new Entity({id : school,label : school})}
                                                    description={new Txt().dict("school_classroom_control").txt(": "+school)}>
                                                    {school}
                                              </InstrumentButton>
                                          ))
                                          }
                                  </ButtonOrganizer>
                              </Card>
                              <InstrumentContainer
                                  onTop={true}
                                  instrument={InstrumentType.TeachersCabinet}
                                  title={new Txt().dict("school_classroom_control")}>
                                  <TeacherCabinetInstrument/>
                              </InstrumentContainer>
                          </RoleBasedContainer>
                      }
                          { this.props.user.classrooms === undefined || this.props.user.classrooms.length === 0 ? null:
                  /*                                Instruments for thous who attends classrooms                                */
                              <RoleBasedContainer>
                                  <Card interactive={true} elevation={Elevation.TWO} className='base-margin base-width'>
                                      <ButtonOrganizer title={new Txt().dict("classrooms_label")} minimal={true} intent={Intent.PRIMARY} buttonSize={ButtonSizes.Large}>
                                          { this.props.user.classrooms.map((classroom,index)=>(
                                              <InstrumentButton
                                                key = {index}
                                                intent={Intent.PRIMARY}
                                                icon='people'
                                                type={InstrumentType.ClassRoom}
                                                entity={new Entity({id : classroom.id, label : classroom.name})}
                                                description={new Txt().dict("classroom_label").txt(": "+classroom.name)}>
                                                {classroom.name}
                                              </InstrumentButton>
                                          ))
                                          }
                                      </ButtonOrganizer>
                                  </Card>
                                  <InstrumentContainer
                                      onTop={true}
                                      instrument={InstrumentType.ClassRoom}
                                      title={new Txt().dict("classroom_label")}>
                                      <ClassroomInstrument/>
                                  </InstrumentContainer>
                              </RoleBasedContainer>
                          }

                        {   /*Instruments for editing custom courses*/  }

                          <RoleBasedContainer excluded={[Role.ROLE_ADMIN]}>
                              <Card interactive={true} elevation={Elevation.TWO} className='base-margin base-width'>
                                  <ButtonOrganizer title={new Txt().dict("all_course")} minimal={true} intent={Intent.WARNING} buttonSize={ButtonSizes.XLarge}>
                                      { this.props.user.teachersCourses ? this.props.user.teachersCourses.map(course=> new Course(course)).map((course,index)=>(
                                          <InstrumentButton
                                              key = {index}
                                              intent={Intent.PRIMARY}
                                              icon='document-open'
                                              type={InstrumentType.TeachersCourses}
                                              entity={new Entity({id : course.id, label : course.getName()})}
                                              description={new Txt().dict("courses_label").txt(": "+course.getName())}>
                                              { course.getName()}
                                          </InstrumentButton>
                                      )) : null
                                      }
                                      <InstrumentButton
                                          key = {-1}
                                          intent={Intent.SUCCESS}
                                          icon='add'
                                          type={InstrumentType.TeachersCourses}
                                          tool={InstrumentType.TemplateEditor}
                                          entity={new Entity()}
                                          description={new Txt().dict(course_creation)}>
                                          <Text text ={new Txt().dict(create_course)} />
                                      </InstrumentButton>
                                  </ButtonOrganizer>
                              </Card>
                              <CourseEditorInstrument/>
                          </RoleBasedContainer>

                     {/*Instruments for studding custom courses*/}

                     <RoleBasedContainer excluded={[Role.ROLE_ADMIN]} >
                             <Card interactive={true} elevation={Elevation.TWO} className='base-margin base-width tiles-container-adjustable'>
                                 <Callout intent={Intent.SUCCESS} icon={null}>
                                     <Text text={new Txt().dict("purchased_courses")}/>
                                 </Callout>

                                 {this.props.user.studentsCourses ?
                                     this.props.user.studentsCourses.map((course,index) =><CourseInfo data={course} key={index}/>) : null
                                 }
                                 <div className='tiles-item-row'>
                                     <InstrumentButton
                                         minimal={true}
                                         intent={Intent.DANGER}
                                         icon='box'
                                         type={InstrumentType.FreeCourses}
                                         entity={new Entity()}
                                         description={new Txt().dict(get_free_courses)}>
                                         <Text text ={new Txt().dict(get_free_courses)} />
                                     </InstrumentButton>
                                 </div>
                             </Card>
                        <FreeCourseBrowser/>
                        <CourseViewerInstrument/>
                     </RoleBasedContainer>

                     { /*                        Multipurpose tools                      */}
                     <ReviewPanel/>
                     <UnderReviewPanel/>
                     <CourseViewerInstrument isReview={true}/>
                     <ToolContainer tool={InstrumentType.TemplateEditor} position="center" >
                            <CourseTemplateEditor/>
                     </ToolContainer>

                     <ToolContainer tool={InstrumentType.ReviewWindow} position="center" >
                         <CourserRequestReview/>
                     </ToolContainer>

                     <ToolContainer tool={InstrumentType.ReviewRejectWindow} position="center" >
                         <CourseRejectReview/>
                     </ToolContainer>

                     <ToolContainer tool={InstrumentType.DeleteCourseTool} position="center" >
                         <CourseDeleteDialog/>
                     </ToolContainer>

                    <ToolContainer tool={InstrumentType.FactViewer} position={Position.BOTTOM}>
                      <FactPlayer/>
                    </ToolContainer>
                     <InstrumentContainer
                         onTop={true}
                         instrument={InstrumentType.Notifications}
                         onClose={()=>{this.props.markMessagesRed(); this.props.closeInstrument()}}
                         title={new Txt().dict(messages_label)}>
                        <MessageBox/>
                     </InstrumentContainer>
                    <LoginDialog joinClassroom={this.args ? this.args.joinClassroom : undefined}/>
                  <InstrumentContainer
                      fullscreen={true}
                      plain={true}
                      autonomous={!this.props.user.isLoggedIn}
                      onTop={true}
                      instrument={InstrumentType.Downloads}
                      title={new Txt().dict("downloads_label")}>
                      <DownloadsControl autonomous={!this.props.user.isLoggedIn}/>
                  </InstrumentContainer>
                     <InstrumentContainer
                         autonomous={true}
                         plain={true}
                         onTop={true}
                         instrument={InstrumentType.SchoolRegister}
                         title={new Txt().dict("schools_label")}>
                         <SchoolRegisterInstrument/>
                     </InstrumentContainer>
                     <InstrumentContainer
                         autonomous={true}
                         plain={true}
                         onTop={true}
                         instrument={InstrumentType.MySchool}
                         title={new Txt().dict("schools_label")}>
                         <MySchoolInstrument/>
                     </InstrumentContainer>
                     <InstrumentContainer
                         autonomous={true}
                         plain={true}
                         onTop={true}
                         instrument={InstrumentType.TrialShd}
                         title={new Txt().dict("schools_label")}>
                         <DrozdovTrialInstrument/>
                     </InstrumentContainer>
                 </div>
                  <Toolbar/>
              </div>
        );
  };
}

const mapStateToProps = (state: AppState) => ({
    user : state.user,
    schoolUser : state.data.schoolUser
});

export default connect(
    mapStateToProps,
    {refresh,useInstrument,getVocabulary,markMessagesRed, closeInstrument}
)(App);