import React, {Component} from 'react';
import {DataType} from "../../actions/data";
import {User} from "../../model/User";
import {Method, Request} from "../../model/Request";
import {sendRequest} from "../../thunks/sendRequest";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {Button, Callout, Dialog, FileInput, Intent} from "@blueprintjs/core";
import {
    cancel_label,
    choose_local_file, choose_local_video, file_new_name, file_uploaded, file_used_name,
    interface_label_16, no_need_to_save,
    open_file,
    override_file, upload_failed,
    upload_file, upload_video, wrong_file_type
} from "../../text/Literals";
import {SaveFileResponse} from "../../api/SaveFileResponse";
import {sendAsync} from "../../service/Connection";
import {Vocabulary} from "../../model/AppData";
import {ExceptionType, noException, onException} from "../../actions/error";
import {becomeIdle, onRequestSent, onUploadEnd, onUploadProgress, Process, ProcessType} from "../../actions/process";
import mime from 'mime-types';
import {closeTool, useTool} from "../../actions/tools";
import {Instruments, InstrumentType} from "../../actions/instruments";
import {FileTypes, isVideo} from "../../service/FileService";
import {abandonData} from "../../actions/data";
import {AppToaster} from "../../service/toaster";
import invalidate from "../../thunks/invalidate";

interface UploadFileDialogProps {
    type : DataType;
    sendRequest : any;
    onException : any;
    noException : any;
    onRequestSent : any;
    becomeIdle : any;
    invalidate : any;
    user : User;
    currentUpload ?: SaveFileResponse;
    intent ?: Intent;
    onSuccess ?: (val : string)=>void;
    vocabulary :Vocabulary;
    pathVar ?: string;
    onUploadEnd : any;
    onUploadProgress : any;
    useTool : any;
    closeTool : any;
    instruments : Instruments;
    process : Process;
    requiredType ?: FileTypes;
    abandonCurrentUpload : any;
}

interface UploadFileDialogState {
    file ?: File;
    expanded : boolean;
    wrongFileType ?: boolean;
}

export interface UploadNotificator {
    onStart(current : number,total : number):void;
    onProgress(current : number, total : number):void;
    onEnd(current: number, total: number):void;
}

class UploadFileDialog extends Component<UploadFileDialogProps,UploadFileDialogState> implements UploadNotificator{
    constructor(props: Readonly<UploadFileDialogProps>) {
        super(props);
        this.state = {expanded : false};
    }

    handleOpen=()=>{
        this.props.useTool(this.props.instruments.type,InstrumentType.UploadDialog);
        this.setState({expanded :true})
    };

    handleClose=()=>{
        this.setState( {expanded : false, file : undefined, wrongFileType : false});
        this.props.closeTool(this.props.instruments.type,InstrumentType.UploadDialog);
    };

    handleSetFile=(file :File)=>{
        if(this.props.requiredType === FileTypes.Video && !isVideo(file.name)){
            this.setState({...this.state,wrongFileType : true, file : undefined});
            this.props.abandonCurrentUpload(this.props.type);
            return;
        }
        this.setState({...this.state,file, wrongFileType : false});
        this.props.sendRequest(this.getRequest(file.name),this.props.type,this.props.user);
    };

    handleSave =()=>{
        this.props.onRequestSent();
        this.props.onException(ExceptionType.Processing);
        const contentType = mime.lookup(this.state.file!.name);
        /*
            if we have file with this name we need to clear the cache
         */
        if(this.props.currentUpload && this.props.currentUpload.overrides) this.props.invalidate(this.state.file!.name,this.props.requiredType);
        sendAsync(Method.PUT, this.props.currentUpload!.uploadUrl, this.state.file!,
            undefined,contentType ? contentType : undefined,0,this).then(resp=>{
            this.props.becomeIdle();
            this.props.noException();
            if(this.props.onSuccess && this.props.currentUpload) this.props.onSuccess(this.props.currentUpload.key);
            this.handleClose();
            AppToaster.show({
                intent: this.props.currentUpload && this.props.currentUpload.overrides ? Intent.WARNING : Intent.SUCCESS,
                message: this.props.vocabulary[file_uploaded]+". "+this.props.vocabulary[no_need_to_save],
                icon : 'saved'
            });
        }, error =>{
            AppToaster.show({
                intent: Intent.DANGER,
                message: this.props.vocabulary[upload_failed]
            });
                console.log(error);
            this.props.onException(error);
            this.props.becomeIdle();
        });

    };

    getRequest=(filename :string):Request =>{
        const extra = this.props.pathVar ? this.props.pathVar +"/" : '';
        return new Request(undefined,this.props.type+extra+filename,Method.GET,this.props.user.token);
    };

    getFileName =()=>{
        return this.state.wrongFileType ?
            <span className='color-text-danger'> {this.props.vocabulary[wrong_file_type]}</span> :
            (this.state.file && this.props.currentUpload ?
            <span className='color-text-success'> {this.props.currentUpload.key}</span>
            : this.props.vocabulary[this.props.requiredType === FileTypes.Video ? choose_local_video :choose_local_file]);
    };

    onEnd= (current: number, total: number)=> {
        this.props.onUploadEnd(current,total);
    };

    onProgress= (current: number, total: number) => {
        this.props.onUploadProgress(current,total);
    };

    onStart=(current: number,total : number) => {
        this.props.onUploadProgress(current,total);
    };

    render(){
        const buttonText : string = this.props.requiredType === FileTypes.Video ? upload_video : upload_file;
        return this.state.expanded ?
            <Dialog
                title={this.props.vocabulary[buttonText]}
                isOpen={true}
                canOutsideClickClose={false}
                onClose={this.handleClose}
                icon='cloud-upload'
                backdropClassName={this.props.process.type === ProcessType.WaitingForServerResponse ? 'instrument-portal' :''}
            >
                <div className="bp3-dialog-body">
                    {this.state.file && this.props.currentUpload && this.props.currentUpload.newName ?
                        <Callout intent={"primary"} >
                            {this.props.vocabulary[file_used_name]}
                        </Callout> :null}
                    <FileInput
                        fill
                        text={this.getFileName()}
                        buttonText ={this.props.vocabulary[open_file]}
                        onInputChange={(e: React.FormEvent<HTMLInputElement>): void => {
                            if (e.currentTarget.files){
                                this.handleSetFile(e.currentTarget.files[0]);
                            }
                        }} />
                    <div className='bp3-dialog-footer form-footer-margin'>
                        < div className='bp3-dialog-footer-actions'>
                            <Button
                                intent={Intent.DANGER}
                                icon='cross'
                                onClick={this.handleClose}
                                >
                                {this.props.vocabulary[cancel_label]}
                            </Button>
                            <Button
                                disabled={!this.props.currentUpload || !this.state.file}
                                intent={!this.props.currentUpload ? Intent.NONE :
                                    (this.state.file && this.props.currentUpload.overrides ? Intent.WARNING :Intent.SUCCESS)}
                                icon='saved'
                                onClick={this.handleSave}
                            >
                                {!this.props.currentUpload || !this.props.currentUpload.overrides ?
                                    this.props.vocabulary[this.state.file && this.props.currentUpload && this.props.currentUpload.newName ? file_new_name :interface_label_16] :
                                    this.props.vocabulary[override_file]}
                            </Button>
                        </div>
                    </div>

                </div>
            </Dialog> :
            <Button
                onClick={this.handleOpen}
                intent={this.props.intent}
                icon='cloud-upload'
            >
                {this.props.vocabulary[buttonText]}
            </Button>
    }

}

const mapStateToProps = (state: AppState) => ({
    user : state.user,
    currentUpload : state.data.currentUpload,
    vocabulary : state.data.vocabulary,
    instruments : state.instruments,
    process : state.process
});
export default connect(
    mapStateToProps,
    {sendRequest,onRequestSent,becomeIdle,onException, noException,onUploadEnd,onUploadProgress,useTool,closeTool,abandonCurrentUpload: abandonData,invalidate}
)(UploadFileDialog);