import React, {Component, ReactNode} from "react";
import {Course} from "../../model/courses/Course";
import {Vocabulary} from "../../model/AppData";
import {Button, Card, Intent, Pre} from "@blueprintjs/core";
import {Elevation} from "@blueprintjs/core/lib/esm/common/elevation";
import {
    course_label,
    homework_of_student, interface_label_16,
    lesson_caption,
    start_download,
    task_label, teachers_message_homework
} from "../../text/Literals";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {firebaseSend} from "../../thunks/firebase";
import {HomeworkType} from "../../model/courses/Lesson";
import DownloadButton from "../buttons/DownloadButton";
import {isImage, isPdf} from "../../service/FileService";
import PostRequestForm from "../forms/PostRequestForm";
import {AnswerHomeworkRequest} from "../../model/courses/AnswerHomeworkRequest";
import {Txt} from "../tools/Text";

interface HomeworkViewProps {
    course : Course;
    student : string;
    lesson : string;
    file : string;
    answer ?: string;
    videoLinks : Vocabulary;
    fileLinks : Vocabulary;
    vocabulary : Vocabulary;
}

interface HomeworkViewState {
    expanded : boolean
}

class HomeworkView extends Component<HomeworkViewProps,HomeworkViewState>{

    constructor(props: Readonly<HomeworkViewProps>) {
        super(props);
        this.state = {expanded : false}
    }

    render(){
        return <div>
            {this.state.expanded ? this.getFullView() : this.getCompactView()}
        </div>
    }

    private getCompactView=() : ReactNode => {
        return <Card elevation={Elevation.TWO} interactive={true} onClick={()=>this.handleExpand(true)} className='tiles-container-vertical'>
            <Pre className='tiles-item-row'>
                {this.getCaption()}
            </Pre>
            <Button minimal={false} intent={Intent.SUCCESS} icon='expand-all' onClick={()=>this.handleExpand(true)} className='tiles-item-row'/>
        </Card>
    };

    private getFullView=() : ReactNode => {
        const lesson = this.getLesson();
        const fileUrl = this.getFileUrl();
        const image = fileUrl ? isImage(this.props.file) : false;
        const pdf = fileUrl ? isPdf(this.props.file) : false;
        return <Card elevation={Elevation.TWO} interactive={false} className='tiles-container-vertical'>
            <Pre className='tiles-item-row'>
                {this.getCaption()}
            </Pre>
            <Pre className='tiles-item-row'>
                {this.getTask()}
            </Pre>
            {lesson ? (
                lesson!.type !== HomeworkType.homework_video ?
                        <div className='tiles-item-row tiles-container-vertical'>
                            {image ? <img className='tiles-item-row' src={fileUrl} width='100%' alt={''}/> : null }
                            {pdf ? <embed className='tiles-item-row'  src={fileUrl} width='100%'/> : null }
                            {fileUrl ?
                                <DownloadButton
                                    className='tiles-item-row'
                                    url={fileUrl}
                                    intent={Intent.SUCCESS}
                                    title={new Txt().dict(start_download)}/>
                                :null}
                        </div>:
                        <div className='tiles-item-row'>
                            {<video src={this.getVideoUrl()}  controls width='100%'/>}
                        </div>
            ):null}
            {this.getAnswerForm()}
            <Button minimal={false} intent={Intent.DANGER} icon='collapse-all' onClick={()=>this.handleExpand(false)} className='tiles-item-row'/>
        </Card>
    };

    private handleExpand(expanded : boolean){
        this.setState({...this.state,expanded})
    }

    private getCaption=() =>{
        const course = this.props.course.contentData;
        const lesson = this.getLesson();
        const lessonName = lesson ? lesson.topic : '';
        return this.props.vocabulary[course_label]+': '+course.name+', '
            + this.props.vocabulary[lesson_caption] +': '+lessonName +', '
            + this.props.vocabulary[homework_of_student] +': '+this.props.student;
    };

    private getTask=() =>{
        const lesson = this.getLesson();
        if(!lesson) return null;
        return this.props.vocabulary[task_label]+': '+ lesson.task;
    };

    private getLesson=()=> {
        return this.props.course.contentData.findLessonById(this.props.lesson);
    };

    private getVideoUrl=() : string | undefined => {
        return this.props.videoLinks[this.props.file];
    };

    private getFileUrl=() : string | undefined => {
        return this.props.fileLinks[this.props.file];
    };

    private getAnswerForm() {
        const request = new AnswerHomeworkRequest(this.props.lesson,this.props.student,this.props.course.id,this.props.answer);
        return <div className='tiles-item-row'>
                    <PostRequestForm
                    default={true}
                    intent={Intent.PRIMARY}
                    url= {''}
                    label={new Txt().dict(teachers_message_homework)}
                    type={request.getType()}
                    data={request}
                    method={request.getMethod()}
                    buttonText={interface_label_16}
                    level={0}
                />
                </div>
    }
}

const mapStateToProps = (state: AppState) => ({
    vocabulary : state.data.vocabulary,
    videoLinks : state.data.videoLinks,
    fileLinks : state.data.fileLinks
});
export default connect(
    mapStateToProps,{firebaseSend}
)(HomeworkView);