import React, {PureComponent} from "react";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import GetRequestForm from "../forms/GetRequestForm";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {DataType} from "../../actions/data";
import {hardware_search, input_hardware_id, product_check_label, software_product} from "../../text/Literals";
import {deviceUserInfoKV, hardwareKV, HardwareUsers} from "../../api/Player";
import {Card} from "@blueprintjs/core";
import KeyValueViewer from "../viewers/KeyValueViewer";
import {checkResultsKV, ProductCheckResult} from "../../api/admin/ProductCheckResult";
import {Software} from "../../api/admin/Software";
import {Elevation} from "@blueprintjs/core/lib/esnext";

interface HardwareInstrumentProps {
    hardware ?: HardwareUsers;
    results ?: ProductCheckResult []
    token : string
    software : Software []
}

interface HardwareInstrumentState {

}

class HardwareInstrument extends PureComponent<HardwareInstrumentProps,HardwareInstrumentState> {
    render(){
        return <div>
            <div className='tiles-container-space'>
                <Card className='tiles-half' elevation={Elevation.THREE}>
                    <GetRequestForm
                        intent={Intent.SUCCESS}
                        url={DataType.ProductCheck}
                        label={software_product}
                        type={DataType.ProductCheck}
                        help={product_check_label}
                        options={this.props.software.map(soft=>soft.product)}
                    />
                </Card>
                <Card className='tiles-half' elevation={Elevation.THREE}>
                    <GetRequestForm
                        intent={Intent.SUCCESS}
                        url={DataType.Hardware}
                        label={input_hardware_id}
                        type={DataType.Hardware}
                        help={hardware_search}
                    />
                </Card>
            </div>
            {this.props.results ? <Card className='tiles-container-space' elevation={Elevation.THREE}>
                    {checkResultsKV(this.props.results,DataType.Hardware, this.props.hardware?.id).map(kv=>
                        <div className='tiles-half' key={kv.key}>
                            <KeyValueViewer
                                kv={kv}
                                token={this.props.token}
                            />
                        </div>
                    )}
                </Card> : null
            }
            {this.props.hardware ?
                <Card elevation={Elevation.THREE} >
                    <div className='tiles-container-space'>
                        {hardwareKV(this.props.hardware).map((kv,index)=>
                            <div className='tiles-one-third' key={kv.key}>
                                    <KeyValueViewer
                                        kv={kv}
                                        token={this.props.token}
                                    />
                            </div>)}
                    </div>
                    {this.props.hardware.devices.map((device, index)=><div key ={index} className={device.authorized ? 'success-padding' : 'error-padding'}>
                        <Card>
                        {
                            <div className='tiles-container-space' >
                                {deviceUserInfoKV(device,this.props.hardware!.id).map((kv, index2)=>
                                    <div className='tiles-half' key={index2}>
                                        <KeyValueViewer
                                            kv={kv}
                                            token={this.props.token}
                                        />
                                    </div>)}
                            </div>
                        }
                        </Card>
                    </div>)}
                </Card>: null}
        </div>;
    }
}

const mapStateToProps = (state : AppState)=>({
    hardware : state.data.hardwareUsers,
    software : state.data.software,
    token : state.user.token,
    results : state.data.productHardwareCheck})

export default connect(mapStateToProps,{})(HardwareInstrument)