import {FirebaseMessage, FirebaseMessageContent, FirebaseMessageType} from "../common/FirebaseMessage";
import {teachers_message_homework} from "../../text/Literals";
import {CourseMessageRequest} from "./CourseMessageRequest";
import {Method} from "../Request";

export class AnswerHomeworkRequest extends CourseMessageRequest{
    user : string;

    constructor(uuid: string, user: string, course: string, text: string='') {
        super(course,uuid,text);
        this.user = user;

        this.adaptor = this.createFormAdaptor();
    }

    getFirebaseMessage=(): FirebaseMessage =>{
        const messageContent : FirebaseMessageContent= {
            lesson : this.uuid,
            content : this.text,
            type : this.user,
        };
        return new FirebaseMessage(this.course,FirebaseMessageType.HomeworkReview,messageContent, Method.PUT);
    };

    getMessageLabel=():string=>{
        return teachers_message_homework;
    }
}