import React, {FC,memo} from "react";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {addModules, Course, CourseData} from "../../model/courses/Course";
import PostRequestForm from "../forms/PostRequestForm";
import {Drawer, Intent, Position} from "@blueprintjs/core";
import {
    courses_label,
    interface_label_16,
    preview_label,
} from "../../text/Literals";
import useInstrument from "../../thunks/useInstrument";
import {CoursePosition, CoursesPositions} from "../../actions/settings";
import {CourseViewerInstrumentEmbedded} from "../instruments/CourseViewerInstrument";
import StudentsCounter from "../courses/StudentsCounter";
import {Text, Txt} from "../tools/Text";

interface CourseEditorProps {
    useInstrument : any;
    course : Course;
    coursesPositions : CoursesPositions;
    showPreview : boolean;
    onClosePreview :()=>void;
}

const CourseEditor  : FC <CourseEditorProps> = props =>  {
  //      console.trace("___render course editor ____");
        const position = new CoursePosition(props.coursesPositions[props.course.id], props.course.contentData.modules);
        return (<div className='form--item-container--center'>
            <StudentsCounter/>
            <div className='form-item-row'>
                <PostRequestForm
                    intent={Intent.PRIMARY}
                    url={props.course.getType()}
                    label={new Txt().dict(courses_label).txt(": ").txt(props.course.getName())}
//                        `${props.vocabulary[courses_label]}: ${props.course.getName()}`}
                    type={props.course.getType()}
                    data={props.course}
                    method={props.course.getMethod()}
                    buttonText={interface_label_16}
                    level={0}
                    ok={() => {
                        course = undefined;
                    }}
                />
            </div>
            <div className='form-item-row'>
                {   props.showPreview ?
                    <Drawer
                        onClose={props.onClosePreview}
                        className='instruments-window'
                        isOpen={props.showPreview }
                        // title={props.vocabulary[preview_label]}
                        title={<Text text={new Txt().dict(preview_label)} />}
                        size={'95%'}
                        isCloseButtonShown={true}
                        canOutsideClickClose={true}
                        position={Position.TOP}>
                        <CourseViewerInstrumentEmbedded
                            isPreview= {true}
                            course={props.course.getActiveContent()}
                            position ={position}
                            onClose ={props.onClosePreview}
                            lesson = { props.course.contentData.modules[position.selectedModule] ?
                            props.course.contentData.modules[position.selectedModule].lessons[position.selectedLesson] : undefined}/>
                    </Drawer>
                : null
                }

            </div>
        </div>);
};

export function copyCourse(course: Course):Course{
    return new Course({
        id: course.id,
        isDraft: course.isDraft,
        content: course.contentData.message(),
        draft: course.contentData.message()
    });
}

let course : Course |undefined;
let data : CourseData;

const mapStateToProps = (state: AppState) => {
    if (!course || course.id !== state.instruments.entity.idAsString()
        || (state.instruments.entity.data && data !== state.instruments.entity.data )) {
//        console.log("***create course***");
    //    console.log(state.instruments.entity.id );
        data = state.instruments.entity.data;
        let modulesAdded : boolean = false;
        course = state.instruments.entity.id ?
            new Course(state.user.getTeachersCourseById(state.instruments.entity.idAsString())) :
            new Course(state.instruments.entity.data);
        if (state.instruments.entity.id && state.instruments.entity.data) {
            const c: Course = new Course(state.instruments.entity.data);
            modulesAdded = true;
            addModules(course.contentData, c.contentData.modules.length, c.contentData.modules[0].lessons.length, state.data.vocabulary);
            course.contentData.saveNoPost();
        }
    //    console.trace(course.contentData);
        if(modulesAdded){
            course = copyCourse(course);
        }
        return {
            coursesPositions : state.settings.coursesPositions,
            course
        }
    } else {
        data = state.instruments.entity.data;
//       console.log("***return existing course***");
        return {
            coursesPositions : state.settings.coursesPositions,
            course
        }
    }
};

export default connect(
    mapStateToProps, {useInstrument}
)(memo(CourseEditor));