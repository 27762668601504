import React,{FC} from "react";
import {Dialog, Intent} from "@blueprintjs/core";
import PostRequestForm from "../forms/PostRequestForm";
import {Txt,Text} from "./Text";
import {email_list_label, invalid_count_filtered, send_request} from "../../text/Literals";
import {MailRequest} from "../../api/MailRequest";

interface MailSendToolProps{
    recipients : string[]
    invalidCount ?: number;
    close : ()=> void;
    onSuccess :(msg ?: string)=>void;
}

const MailSendTool : FC<MailSendToolProps> = props => {
    if (!props.recipients || props.recipients.length === 0) return null;
    const request = new MailRequest(props.recipients);
    return <Dialog
        transitionDuration={200}
        isOpen={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        className="login-container-wide"
        onClose={props.close}
    >
        <div className="bp3-dialog-body">
            {props.invalidCount ? <div >
                <Text className='color-danger' text = {new Txt().dict(invalid_count_filtered).txt(props.invalidCount +'')}/>
            </div>: null}
            <PostRequestForm
                default={true}
                intent={Intent.PRIMARY}
                url= {request.getType()}
                label={new Txt().dict(email_list_label)}
                type={request.getType()}
                help={''}
                data={request}
                method={request.getMethod()}
                buttonText={send_request}
                ok ={props.onSuccess}
                cancel={props.close}
            />
        </div>
    </Dialog>
}

export default MailSendTool;