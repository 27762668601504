import mime from  'mime-types';

export enum FileTypes {
    Any= "",
    Video ='video',
    Image = 'image',
    PDF = 'application/pdf',
    Document = 'officedocument'
}

export function isVideo(filename: string) : boolean {
    return isOfType(filename,FileTypes.Video);
}

export function isPdf(filename : string) : boolean {
    return isOfType(filename,FileTypes.PDF);
}

export function isImage(filename: string) : boolean {
    return isOfType(filename,FileTypes.Image);
}

export function isOfType(filename : string, type :string) {
    const m = mime.lookup(filename);
    return m ? m.indexOf(type) >=0 : false;
}
